.no-scroll {
    overflow: hidden;
  }

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1000;
}

.popupContainer {
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    overflow: hidden;
    height: auto;
    font-family: Poppins;
    width: 600px;
    font-family: Poppins;
    max-height: 90vh;
    overflow-y: auto;
  }

.mainContainer {
  padding: 25px 50px;
  background-color: #FFFFFF;
}

.mainContainer h1 {
  color: #727271;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.mainContainer p {
  color: #727271;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;


}

.line {
  border: 1px solid #F1F1F1;
  height: 1px;
  margin-bottom: 20px;
}

.featuresDiv {
  padding: 25px;
  background: #F8F8F8;
}

.profilepic {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.verifiedDiv {
  height: 22px;
  background-color: rgba(31, 191, 194, 0.10);;
  padding: 5px 10px;
  color: #1FBFC2;
  font-size: 8px;
  font-weight: 400;
}

.messageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #F5790C;
  color: #FFFFFF;
}

.crossButton {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;;
}




.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #1FBFC20D;
}

.header p {
  font-size: 18px;
  font-weight: 500;
  color: #121212;
}

.mainContainer1 {
  background-color: white;
  padding: 25px;
  height: 30vh; 
  overflow-y: auto; 
  font-family: Poppins;
  position: relative;
  width: 100%;
}

.chat {
  padding: 25px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F1F1F1;
}

.name{
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 2px;
}

.message{
  color: #4D4D4D;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 0px;
}

.time {
  color: #727271;
  font-size: 6px;
  font-weight: 400;
}

.headerChatScreen {
  padding: 25px;
  color: #727271;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #1FBFC20D;
}

.recievedMessageDiv {
  display: flex;
  gap: 12px;
  margin-bottom: 15px;
}

.sentMessageDiv {
  display: flex;
  gap: 12px;
  margin-bottom: 15px;
  justify-content: flex-end;
  align-items: center;
}

.recievedMessage {
  border-radius: 5px;
  background: rgba(114, 114, 113, 0.10);
  padding: 10px 15px;
}

.sentMessage {
  border-radius: 5px;
  background: rgba(114, 114, 113, 0.20);
  padding: 10px 15px;
}

.recievedMessage p {
  color: #727271;
  font-size: 10px;
  font-weight: 400;
}

.inputElement {
  width: 410px;
  height: 48px;
  border-radius: 25px;
  background: #F1F1F1;
  position: fixed;
  display: flex;
  padding-right: 10px;
  align-items: center;
}

.inputElement input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
}