.TinderHomePage {
    width: 100%;
    background-color: #F6F9FE;
    padding-bottom: 80px;
  }


  .matchesHeaderDiv {
    padding: 25px 100px;
    background-color: #1FBFC2;
    color: #FFFFFF;
    font-family: Poppins;
  }

  .profileCardDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
  }

  .mainDiv {
    padding: 100px;
    background-color: #F6F9FE;
  }

  .editDiv {
    padding: 100px;
    background-color: #FFFFFF;
  }

  .profilePicDiv {
    display: flex;
    justify-content: center;
  }

  .profilePicDiv img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }

  .plusIcon {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 2px solid #FFFFFF;
    background-color: #6C6C6C;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 35px;
    padding-bottom: 9px;
    bottom: 5%;
    right: 5%;
    cursor: pointer;
  }

  .formDiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 75px;
    font-family: Poppins;
    gap: 5%;
    justify-content: space-between;
  }

  .submitButton {
    width: 164px;
  height: 50px;
  border: 1px solid #F5790C;
  background: #F5790C;
  color: #FFFFFF;
  
  }
.editInput{
    margin-top: 12px !important;
    background-color: rgba(243, 243, 243, 0.5);
    border-color: rgb(217, 217, 217);
    height: 52px;

}


#tooltip {
    position: relative;
    cursor: pointer;
  }
  
  #tooltip:hover::after {
    content: attr(data-tooltip); 
    position: fixed;
    /* bottom: 100%; */
    top:"80%";
    left: 0;
    background-color: #61677f;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    white-space: normal; /* Allow the text to wrap inside the tooltip */
    width: auto;
    max-width: 250px; /* Optional: to control tooltip width */
    word-wrap: break-word;
    z-index: 10; /* Ensure the tooltip is on top of other elements */
  }
  
  #tooltip:hover {
    overflow: visible; /* Allow the text to overflow when hovered */
  }
  
  