.profile-main {
    margin-top: 91px;
    display: flex;
}

.profile-left-side {
    width: 20%;
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 84.6vh;
    overflow-y: auto;
}

.sidebar {
    display: flex;
    flex-direction: column;
}

.sidebar-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 5px;
}

.sidebar-icon {
    font-size: 20px;
    margin-right: 10px;
}

.sidebar-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.logout-icon {
    font-size: 18px;
    margin-right: 10px;
}

.logout-btn:hover {
    background-color: #c0392b;
}

.profile-right-side {
    width: 80%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #F6F9FE;
    height: 84.6vh;
    overflow-y: auto;
}

.sidebar-item:hover {
    background-color: #F0F1F6;
    border-radius: 15px;
}

.sidebar-item.active {
    background-color: #F0F1F6;
    color: #1FBFC2;
    border-radius: 15px;
}

.sidebar-item.active .sidebar-icon {
    color: #1FBFC2;
}


/* Register your Zaanvar CSS */

.register-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 84.6vh;
    overflow-y: auto;
    gap: 20px;
}

.register-left {
    flex: 2;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 80%;
}

.register-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.register-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 400px;
    height: 213px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 0px;
}

.plus-box {
    width: 90%;
    height: 120px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

}

.plus-icon {
    font-size: 30px;
    margin-bottom: 10px;
}

.register-plus-icon-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #909AA3;
}


.register_box {
    gap: 0px;
    border-radius: 5px 5px 0px 0px;
}

.register_box-card {
    width: 48%;
    height: auto;
    border-radius: 5px;
    background-color: #ffffff;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.register-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.register-card-content {
    padding: 10px;

}

.register-card-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.card-subtitle {
    font-size: 14px;
    color: #777;
    margin: 5px 0 0 0;
}


.edit-mode,
.display-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

}

.register-edit-input {
    flex: 1;
    font-size: 16px;
    border: none;

}

.save-button,
.edit-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 25px;
    padding: 5px;
}

.register-card-title {
    flex: 1;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.register-month-date {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.register-month-date p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.register-Profile-Completion {
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.register-Profile-Completion {
    width: 100%;
    margin: 10px 0;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.progress-bar-container {
    width: 100%;
    height: 10px;
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background: #1FBFC2;
    border-radius: 5px;
    transition: width 0.3s ease;
}

.register-icon-text {
    display: flex;
    align-items: center;
    gap: 10px;
}

.thumb-icon {
    font-size: 20px;
    color: #1FBFC2;
}

.matches-text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1FBFC2;
    margin-top: 18px;
}


.register-btn-card {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #F5790C;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.register-btn-card:hover {
    background-color: #d46b0e;
}



.register_box-card:hover {
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 12px;  */
    cursor: pointer;
    background-color: #F8F2EE;
}

.register_box-card:active {
    transform: scale(1);
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  */
}



/* Modal.css */
.register-post-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.register-post-modal-content {

    border-radius: 8px;
    width: 900px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.register-post-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4D4D4D;
    padding: 10px 20px;
}

.register-post-title {
    flex: 1;
    text-align: center;
    margin: 0;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.register-post-close-btn {
    padding: 10px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.register-body {
    background-color: #F6F9FE;
    height: 80vh;
    overflow-y: auto;
}

.pet-form-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.PetName {
    padding-bottom: 15px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-field {
    flex: 1;
    margin: 0 10px;
}

.form-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-field input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.register-post-submit-btn {
    align-self: flex-start;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #F5790C;
    color: white;
    border: none;
    margin: 30px auto;
    border-radius: 5px;
    cursor: pointer;
    width: 250px;
    height: 50px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
}

.uploaded-image {
    border-radius: 50%;
    margin: 0 auto;
    width: 120px;
    height: 120px;
}

.uploaded-image-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #909AA3;
    margin-top: 15px;
    cursor: pointer;
}


/* FAQ cSS FILE */



.faq-title {
    color: #333;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.6px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.faq-sub-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #727271;
}

.faq-item {
    background-color: #ffffff;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    gap: 15px;
    margin-bottom: 15px;
}



.faq-question-container {
    display: flex;
    justify-content: space-between;
}

.faq-question {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #131313;
}

.faq-answer {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #909AA3;
}


/* PROFILE CSS FILE */

.profile-settings-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.profile-section {
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.profile-section.active {
    border-bottom: 2px solid #d46b0e;

}

.account-form-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
}

.account-form-lable {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #333;
}

/* Input wrapper styles */
.account-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

/* Input field styles */
.account-form-input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}

.account-form-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Edit icon styles */
.account-edit-icon {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: #888;
    cursor: pointer;
}

.account-edit-icon:hover {
    color: #555;
}

.profile-picture-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0; 
  }
  
  .profile-picture {
    width: 150px;
    height: 150px; 
    border-radius: 50%; 
    object-fit: cover; 
    border: 3px solid #000000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  