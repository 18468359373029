.scrolling-lists-container {
    display: flex;
    width: 50%;
  }
  
  .list-container {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px; /* Limit the height of the list to enable scrolling */
  }
  
  .list li {
    padding: 5px 0;
    font-size: large;
    font-weight: 600;
    border-bottom: 1px solid;
    color: blue;
  }
  
  .list li:nth-child(even) {
    background-color: #f9f9f9; /* Alternate background color for better readability */
    font-size: large;
    font-weight: 600;
    border-bottom: 1px solid;
    color: green;
  }
  