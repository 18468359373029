.no-scroll {
    overflow: hidden;
  }

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContainer {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  overflow: hidden;
  height: auto;
  max-height: 90%;
  font-family: Poppins;
  width: 1050px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #4D4D4D;
}

.header p {
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.mainContainer {
    background-color: #F6F9FE;
    /* min-height: 100vh; */
    padding: 100px;
    max-height: 90vh; /* Constrain height to make room for scroll */
    overflow-y: auto; /* Enable scrolling when overflowed */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mainContainer p {
    font-size: 16px;
    font-weight: 600;
}

.addButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #A6ACB8;
  height: 175px;
  width: 175px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 25px;
  background-color: #FFFFFF;
}

.addButtonDiv p{
  margin: 0px;
  font-size: 100px;
  font-weight: 100;
  color: #A6ACB8;
}

.formDiv2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.submitButton {
  width: 250px;
  height: 50px;
  background: #F5790C;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
}

.previewImg {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.customInput{
  padding: 0px !important;
}