.image-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 300px;
}

.image-back {
  position: absolute;
  top: 0;
  left: 10%;
  z-index: 1;
  object-fit: cover;
  width: 350px;
  height: 250px;
  border-radius: 15px;
}

.image-front {
  position: absolute;
  top: 35%;
  left: 55%;
  object-fit: cover;
  z-index: 2;
  border: 7px solid white;
  width: 250px;
  height: 200px;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .image-front {
    width: 150px;
    height: 200px;
    margin-left: -60px;
  }
  .image-back {
    margin-top: -50px;
    width: 230px;
    height: 200px;
  }
  .home-about-dec {
    width: 340px;
    margin: 0 auto;
  }
  .home-about-name {
    margin-left: 15px;
  }
}

@media (max-width: 576px) {
  .image-front {
    width: 55%;
  }
}

.home-about-name {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

.home-about-dec {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #727271;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
