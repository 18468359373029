/* li {
  color: orange;
  border: 1px solid orange;
  padding: 5px 10px;
  border-radius: 1.5rem;
} */

.customerNavbar {
  height: 88px;
  background: transparent;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
  transition: top 0.6s;
}




.customerNavbar .nav-elements ul li a {
  text-decoration: none;
  color: #333; /* Adjust text color */
  padding: 10px 15px;
  border-radius: 4px;
 
  transition: background-color 0.3s ease;
}

.customerNavbar .nav-elements ul li a:hover {
  background-color: rgb(17, 155, 209);
   /* Blue background */
   color: #fff; /* White text */
}
.customerNavbar .nav-elements ul li.active-item a {
  color: #fff; /* White text color */
  background-color: rgb(17, 155, 209);
}



