.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popupForm {
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
  width: 50%;
  max-width: 90%;
  height: 70%;
  overflow: hidden; 
  position: relative;
  }
  
  .formTitle {
  text-align: left;
  background-color: #1FBFC20D;
  height: 60px;
  width: 100%;
  padding: 10px 30px;
  position: sticky; 
  top: 0; 
  z-index: 100;

  }
  .form{
    padding: 20px 30px;
    max-height: calc(100% - 60px); 
    overflow-y: auto;
  }
  .SelectFieldContainer{
    width:100%;
    display:flex;
    flex-direction: column;
    gap:20px;
    position:relative;

  }
  .SelectFieldContainer label{
    font-weight: 500;
  }
  .SelectFieldContainer select{
    width:100% !important;
    height:45px !important;
    margin-bottom:30px;
    padding:6px !important;
    border: 1px solid #D9D9D9BF !important;
  }
  .SelectFieldContainer input{
    width:100% !important;
    height:45px !important;
    margin-bottom:30px;
    padding:6px !important;
    border: 1px solid #D9D9D9BF !important;
  }
  .SelectFieldContainer textarea{
    width:100%;
    margin-bottom:30px;
    padding:6px !important;
    border: 1px solid #D9D9D9BF;
  }
 .TimeOfTheDayContainer{
  display:flex;
  width:100%;
  gap:10px;
  justify-content: space-between;
  position:relative;
 }
 .TimeOfTheDayContainer button {
  width:30%;
  background-color:  #F3F3F380;
  border: 1px solid #D9D9D9BF;
  height:100px;
  border: 1px solid #D9D9D9BF;
 }
 .DateDurationMainContainer{
  display:flex;
  width:100%;
  justify-content:space-between;
  gap:10px;
  margin-top:30px;
 }
 .DateContainer{
  width:45%;
  display:flex;
  flex-direction: column;
  gap:20px;
  position:relative;
 }
 .DatesContainer{
  width:45%;
  display:flex;
  flex-direction: column;
  gap:20px;
  position:relative;
 }

 .DateContainer label{
  width:100%;
  font-weight:500;
 }
 .DateContainer input{
  width:100% !important;
  height: 45px !important;
  border: 1px solid #D9D9D9BF;
 }
 .checkboxMainContainer{
  display:flex;
  width:100%;
  gap:20px;
  position:relative;
 }
 .checkboxContainer{
  display:flex;
  gap:10px;
 }
 .BtnContainer{
  margin-block:40px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
 }
.BtnContainer button{
  background-color: #F5790C;
  color:white;
  padding: 6px 20px;
}
.MdReminderContainer{
  width:100%;
} 
.MdReminderContainer label{
  font-weight:500;
  margin-bottom:20px;
  
}

.MonthlyGrowthMainContainer{
  display:flex;
  flex-direction: column;
  gap:20px;
  width:100%;
}
.MonthlyGrowthContainer{
  display:flex;
  flex-direction: column;
  gap:16px;
  width:100%;
}
.MonthlyGrowthContainer label{
  position: relative;
}
.errorText{
  position:absolute;
  color:red;
  bottom:10px;
  font-size:14px;
}