.fp-signmainHeader {
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
    background: #F6F9FE;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
  }
  .fp-containerbody {
    background: #f6f5f7;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    /* height: 100vh; */
    /* margin: -40px 0 90px; */
    margin: auto;
    margin-top: 50px;
    height: 80vh;
    margin-bottom: 50px;
  }
  .fp-containeronly {
    background-color: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
    position: relative;
    /* overflow: hidden; */
    width: 868px;
    max-width: 100%;
    min-height: 480px;
  }
  
.fp-zaanvar-icon{
    justify-content: center;
    margin-top: 75px;
    /* margin-left: 210px; */
    
    display: flex;
    align-items: center;
  }
  .fp-container {
    display: flex;
    height: 100vh;
   
    
  }
  
  /* Left and right sides equally split */
  .fp-left-side {
    width: 50%;
   
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .fp-field{
    background-color: none;
    height: 30px;
    margin-top: 0px;
    font-size: 12px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .fp-field::placeholder{
    font-size: 12px;
  }
.fp-fixed-bg-image {
    position: absolute;
    top: 60px;
    left: 50px;
   
    z-index: 0;
    /* margin-left: 50px; */
    pointer-events: none; /* So clicks pass through to elements above */
position: relative;
border-radius: 20px;
width: 70%;
height: 70%;


  }
  .forgotpass-welcome{
    font-size: 8px;
   margin-top: 48px;
   
  }
  .forgetpass-l1{
    color: #727271;
    font-size: 8px;
 
    margin-top: -10px;
/* margin-left: 25px; */

  }
  .fp-img{
    background: #F0F0F0;
    padding: 0px;
    border-radius:20px ;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 40px;
    height: 80%;
    width: 40%;
  }
  .fp-left-side {
    width: 50%;
   
    justify-content: center;
    align-items: center;
    padding: 0px;
  }


  @media (max-width: 768px) {

    .fp-img{
  background: rgb(240, 240, 240);
  padding: 0px;
  border-radius: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
  /* margin-left: -41px; */
  margin-right: 28px;
  /* height: 80%; */
  width: 81%;
    }
    .sp-left-side {
        width: 95%;
      }
  }
  @media (max-width: 425px) {
    .fp-img{
        display: none;
    }
  }

  @media (max-width: 320px) {
    .fp-img{
        display: none;
    }
    .fp-containeronly {
        background-color: #fff;
        /* border-radius: 10px; */
        /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
        position: relative;
        /* overflow: hidden; */
        width: 900px;
        max-width: 100%;
        min-height: 480px;
      }
      .fp-left-side {
        width: 55vh;
      }
      .fp-containeronly {     
        width: 817px;
        max-width: 296%;
        margin-left: -96%;
    }
  }




/*---------------------------------------------------OTP------------------------------------------------------*/

.otp-Header {
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
    background: #F6F9FE;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
  }
  .otp-containerbody {
    background: #f6f5f7;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    /* height: 100vh; */
    /* margin: -40px 0 90px; */
    margin: auto;
    margin-top: 50px;
    height: 80vh;
    margin-bottom: 50px;
  }
  .otp-containeronly {
    background-color: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
    position: relative;
    /* overflow: hidden; */
    width: 868px;
    max-width: 100%;
    min-height: 480px;
  }
  .otp-left-side {
    width: 50%;
   
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

.otp-container {
    display: flex;
    height: 100vh;
   position: relative;
   left: -50px;
    
  }
  .otp-img {
    background: #EFEFEF;
    ;
    padding: 0px;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    left: 45px;
    height: 80%;
    width: 47%;
}
  
.otp-zaanvar-icon{
    justify-content: center;
    margin-top: 70px;
    /* margin-left: 210px; */
    
    display: flex;
    align-items: center;
  }
  .otp-l1{
    color: #727271;
    font-size: 8px;
 
    margin-top: -10px;
/* margin-left: 25px; */

  }
  
.otp-form {
    position: relative;
    left: -10px;
    margin-top: 30px;
    margin-left: 4px;
    margin-right: 20px;
    width: 77%;
}
.otp-fixed-bg-image {
    position: absolute;
    top: 60px;
    left: 50px;
   
    z-index: 0;
    /* margin-left: 50px; */
    pointer-events: none; /* So clicks pass through to elements above */
position: relative;
border-radius: 20px;
width: 70%;
height: 70%;


  }

@media (max-width: 320px) {
    .otp-Header {
        overflow: hidden;
    }
    .otp-img {
        display: none;
    }
    .otp-form {
        position: relative;
        left: -10px;
        margin-top: 30px;
        margin-left: 51px;
        margin-right: 20px;
        width: 61%;
    }
    .otp-bottom{
        padding-left: 40px;
    }
    .otp-containeronly {
        background-color: #fff;
        /* border-radius: 10px; */
        /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
        position: relative;
        /* overflow: hidden; */
        width: 900px;
        max-width: 100%;
        min-height: 480px;
      }
      .otp-left-side {
        width: 55vh;
      }
      .otp-containeronly {     
        width: 817px;
        max-width: 296%;
        margin-left: -96%;
    }
}


/*---------------------------------------------reset Password -----------------------------------------*/
.rp-signmainHeader {
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
    background: #F6F9FE;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
  }
  .rp-containerbody {
    background: #f6f5f7;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    /* height: 100vh; */
    /* margin: -40px 0 90px; */
    margin: auto;
    margin-top: 50px;
    height: 80vh;
    margin-bottom: 50px;
  }
  .rp-containeronly {
    background-color: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
    position: relative;
    /* overflow: hidden; */
    width: 868px;
    max-width: 100%;
    min-height: 480px;
  }
.rp-Header {
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
    background: #F6F9FE;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
  }
  .rp-containerbody {
    background: #f6f5f7;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    /* height: 100vh; */
    /* margin: -40px 0 90px; */
    margin: auto;
    margin-top: 50px;
    height: 80vh;
    margin-bottom: 50px;
  }
  .rp-containeronly {
    background-color: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
    position: relative;
    /* overflow: hidden; */
    width: 868px;
    max-width: 100%;
    min-height: 480px;
  }
  
  


.rp-img {
    background: #EFEFEF;
    ;
    padding: 0px;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    left: 65px;
    height: 80%;
    width: 40%;
}
.rp-form {
    position: relative;
    left: 50px;
    margin-top: 30px;
    margin-left: 4px;
    margin-right: 20px;
    width: 68%;
}
.rp-container {
    display: flex;
    height: 100vh;
   position: relative;
   left: -50px;
    
  }
  .rp-left-side {
    width: 50%;
   
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .rp-zaanvar-icon{
    justify-content: center;
    margin-top: 75px;
    /* margin-left: 210px; */
    
    display: flex;
    align-items: center;
  }
@media (max-width: 768px) {

.rp-img {
    background: #EFEFEF;
    padding: 0px;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    left: 65px;
    height: 75%;
    width: 40%;
}
.rp-bottom{
    display: none;
}
}

@media (max-width: 320px) {
    .rp-signmainHeader{
        overflow: hidden;
    }
    
    .rp-img {
        display: none;
    }
    .rp-bottom{
        display: none;
    }
    .rp-containeronly {     
        width: 817px;
        max-width: 296%;
        margin-left: -96%;
    }
    .rp-left-side {
        width: 55vh;
      }

}

/*--------------------------------------------password sucess ------------------------------------------*/


  .ps-l1{
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    margin-left: 30px;
    margin-bottom: 20px;
    
    
  }
  .ps-l2{
    font-family: Poppins;
font-size: 10px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.30000001192092896px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-left: 15px;
margin-bottom: 20px;

  }
  .ps-img{
   background: #F4F4F4;

    padding: 0px;
    border-radius:20px ;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 169px;
    height: 80%;
    width: 60%;
  }
  .ps-left-side {
    width: 50%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: -127px;
}
.ps-login-form {
    margin-top: 30px;
    margin-left: 54px;
    margin-right: 20px;
    width: 83%;
    position: relative;
    left: 60px;
}
.ps-login-button {
    background: #F5790C;
    width: 111%;
    color: white;
    font-size: 10px;
    padding: 10px;
}
.ps-zaanvar-icon {
    justify-content: center;
    margin-top: 75px;
    /* margin-left: 210px; */
    display: flex;
    position: relative;
    align-items: center;
    left: 99px;
}
.ps-signmainHeader {
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
    background: #F6F9FE;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
}



@media (max-width: 768px) {


.ps-img {
    background: #F4F4F4;
    padding: 0px;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
     margin-left: 0px; 
    height: 80%;
    width: 54%;
    position: relative;
    left: 117px;
}
.ps-l1 {
   
    width: 108%;
}
.ps-l2 {

    width: 125%;
}
.ps-l1 {
    margin-left: 18px;
}
}

@media (max-width: 320px) {
    .ps-img{
        display: none;
    }

}