@media (max-width: 768px) {
  .service-card-new-one {
    margin: 20px 40px;
  }
}

.Our-service-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

@media (max-width: 756px) {
  .custom-img {
    max-width: 110px;
    height: auto;
    margin: 0 auto;
  }
}
