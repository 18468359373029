.CustomerNavbar {
  margin: 10px 20px;
}

.CustomerDashboard {
  /* background: linear-gradient(to right bottom, #fff687, #f6416c); */
  height: 100%;
  width: 100%;
}

.CustomerDashboardBody {
  margin: 10px 20px;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: xx-large;
  color: white;
}

.quoteText {
  font-size: initial;
}

.upcomingEventCard {
  background-color: white;
  margin-bottom: 5px;
  border: 1px solid white;
  padding: 5px;
  border-radius: 10px;
}

.upcomingEventCardClinicName{
  border-right: 2px solid ;
  margin-right: 1rem;
}

.upcomingEventCardImage{
  border-radius: 10px;
  margin-left: 2px;
}

p{
  font-size: .8rem;
  font-weight: 600;
}

.petDiaryCard{
  background-color: white;
  margin-bottom: 5px;
  border: 1px solid white;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.serviceDescription{
  text-align: justify;
  text-wrap: balance;
}

.serviceLi{
  font-size: 1rem;
}

.sessionTitle{
  font-size: 1rem;
  /* color: brown; */
  font-weight: 800;
}