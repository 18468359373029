.hero {
    position: relative;
    height: 60vh;
    /* min-height: 600px; */
    width: 100%;
    color: #fff;
    margin-top: 70px;
    overflow: hidden;
  }

  .heroBackground {
    position: absolute;
    inset: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(0.5);
  }
    
  .heroDiv {
    position: relative;
    z-index: 1;
    top: 50%;
    left: 10%;
  }

  .heroDiv h1 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 40px;
  }

.aboutDiv {
    width: 80%;
    margin-top: 125px;
    display: flex;
    gap: 100px;
    margin-left: auto;
    margin-right: auto;
}

.aboutDiv img {
    width: 615px;
    height: 394px;
    border-radius: 25px;
}

.aboutDiv div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.aboutDiv h1 {
    color: #131313;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
}

.aboutDiv p {
    color: #727271;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%
}

.aboutDiv button {
    background: #F5790C;
    width: 290px;
    height: 50px;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
}

.reunitedDiv {
    width: 80%;
    margin-top: 125px;
    margin-left: auto;
    margin-right: auto;
}

.reunitedDiv h1 {
    color: #131313;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
}

.reunitedCardsDiv {
    display: flex;
    justify-content: space-between;
}

.reunitedCard {
    width: 30%;
    border: 1px solid #727271;
    background: #FFF;
    margin-top: 25px;
}

.reunitedCard img {
    width: 100%;
    height: 250px;
}

.reunitedCard div {
    padding: 25px;
}

.reunitedCard h1 {
    color: #131313;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
}

.reunitedCard p {
    color: #727271;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}

.readMore {
    color: #F5790C;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;

}
/* lost and found model css*/

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background 0.3s ease-in-out;
  }
  
  .modalContent {
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    width: 80%;
    max-width: 450px;
    color: #fff; 
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15); 
    transform: translateY(-30px);
    animation: fadeInUp 0.5s forwards; 
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .closeButton, .confirmButton {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.2s ease, background-color 0.3s ease;
  }
  
  .closeButton {
    background: #333;
    color: #fff;
    border: 2px solid #fff;
  }
  
  .confirmButton {
    background: #4caf50;
    color: white;
    border: 2px solid #4caf50;
  }
  
  .closeButton:hover, .confirmButton:hover {
    transform: translateY(-4px); 
  }
  
  .closeButton:focus, .confirmButton:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .modalContentText{
    color: #141414;
    font-weight: 900;
    font-style: bold;
    font-size: 16px;
  }
  
  .disabledButton {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  
  
  
  .hiddenCard {
    opacity: 0.5;
    pointer-events: none;
    position: relative;
  }
  
  .hiddenBand {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    z-index: 10;
  }
  
  
  
  
