.GBMainContainer{
    width:100vw;
    background-color:#f6f9fe;
    margin-top:88px;
    padding-top:40px;
    
}
.GBMainContentContainer{
    width:95%;
    margin-inline:auto;
    background-color: white;
    padding:40px;
    display:flex;
    flex-direction: column;
    gap:20px;
}
.GBSelectedPetsContainer{
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items:center;
}
.GBSelectedPetsCardContainer{
    display:flex;
    gap:10px;
}
.GBSelectedPetsCard{
    display:flex;
    gap:20px;
    width:300px;
    height:75px;
    border:1px solid #1fbfc2;
    padding:8px;
    border-radius:8px;
}
.GBSelectedPetsCardImgContainer{
    width:60px;
    height:60px;
}
.GBSelectedPetsCardDetailsContainer{
    display:flex;
    flex-direction: column;
    gap:5px;
}
.GBHomeContainer{
    width:130px;
    height:40px;
}
.GBHomeContainer select{
    width:100%;
    height:100%;
    padding: 6px 10px !important;
    border:1px solid #bebebe;
    color:#636981;
}
.GBUnderline{
   border:1px solid #f1f1f1; 
}