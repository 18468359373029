.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  padding-left: 100px;
  padding-right: 90px;
  box-shadow: 0px 0px 10px 0px #0000001a;
}

@media (max-width: 1024px) {
  .header {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: 768px) {
  .header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 480px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dropDownMain {
  position: absolute;
  top: 4vw;
  left: 0;
  right: 0;
  background-color: #f6f9fe;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 100%;
  padding: 20px;
  margin-left: 100px;
  margin-right: 100px;
}

.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 4rem;
}

.logo {
  width: 90px;
  height: auto;
  cursor: pointer;
  margin: 0px !important;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.profileIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid #ddd;
}

.menuIcon {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #131313;
  position: relative;
  transition: background-color 0.3s ease;
}

.menuIcon::before,
.menuIcon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #131313;
  transition: all 0.3s ease;
}

.menuIcon::before {
  top: -8px;
}

.menuIcon::after {
  bottom: -8px;
}

.menuIcon.open {
  background-color: transparent;
}

.menuIcon.open::before {
  top: 0;
  transform: rotate(45deg);
}

.menuIcon.open::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.activeLocation {
  color: orange;
  font-weight: bold;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.navLink {
  font: 500 1rem/1 Poppins, sans-serif;
  color: #131313;
  text-decoration: none;
  transition: color 0.3s ease;
}

.linkEffect::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, transparent, #f5790c, transparent);
  background-size: 300% 100%;
  opacity: 0;
  transition: opacity 0.5s;
}

.linkEffect:hover {
  color: #000000;
}

.linkEffect:hover::after {
  opacity: 1;
  animation: shimmer 2s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.active {
  color: #f5790c;
}

/* Additional styling for menu open state */
.open {
  background-color: #444;
}

.loginButton {
  font: 500 1rem/1 Poppins, sans-serif;
  color: #f5790c;
  background-color: #fff;
  border: 2px solid #f5790c;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.loginButton:hover {
  background-color: #f5790c;
  color: #fff;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navLink {
  position: relative;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.dropdownItem {
  display: block;
  padding: 10px;
  color: #000;
  text-decoration: none;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.dropDownMain {
  position: fixed;
  top: 6vw;
  background-color: #f6f9fe;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 54vw;
  right: 0;
  border-radius: 24px;
}

.navSeperator {
  width: 1px;
  height: 100%;
  background: black;
}

/*------------------css--------------*/
.userMenuDropdown {
  position: relative;
  width: 150px;
  height: 30px;
  gap: 0px;
  padding-left: 10px;
  border: 1px solid #bebebe;
}

.userMenuDropdown2 {
  position: relative;
  gap: 0px;
}

.userMenu {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.userMenu2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 0px;
}

.userMenuTrigger {
  color: #bebebe;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-left: 30px;
}

.dropDownSvg {
  display: flex;
  justify-content: start;
  text-align: start;
  margin-top: -12px;
  margin-right: 19px;
}

.userDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.dropdownItem {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdownItem:hover {
  background: #f0f0f0;
}

.loggedInMenu {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

/* Add any additional styles for responsive design or other needs */

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }

  .navigation {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
  }

  .headerContent {
    padding: 1rem 2rem;
  }

  .navigation.open {
    display: flex;
  }

  .navLink,
  .loginButton {
    width: 100%;
    text-align: center;
    padding: 0.75rem;
  }

  .logo {
    width: 63px;
    margin: 0px !important;
  }
}
