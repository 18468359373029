/* *{
  color: #005555;
} */


.holidaysCard, .leavesCard, .imageDiv{
    flex-grow: 1;
    /* background-color: red; */
    margin: 0 10px;
    height: 12vh;
    border-radius: 10px;
    padding: .25rem;
    /* color: white; */
}


/* .leavesList{
    display: flex;
    justify-content: space-between;
align-items: center;
} */

.typeOfLeave{
    gap: 1rem;
    display: flex;
}

.leaveItem{
    font-size: 2.5rem;
    font-weight: 700;

}


.dateList{
    margin-right: 1.5rem;
}


.leavesList p{
    width: 20%;
  }

  .leavesList p:nth-child(1){
    width: 40%;
  }

  .leaveCard::-webkit-scrollbar {
    width: 6px; 
  }
  

  .leaveCard::-webkit-scrollbar-track {
    background: #007bff; 
  }

  .leaveCard::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 3px;
  }