.graphSection{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    }
    
    .item{
    
        flex-grow: 1;
        flex-basis: 200px;
    }
    
