.no-scroll {
  overflow: hidden;
}

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #1fbfc20d;
}

.header p {
  font-size: 18px;
  font-weight: 500;
  color: #121212;
}

.popupContainer {
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  overflow: hidden;
  height: auto;
  /* max-height: 90%;
    overflow-y: auto; */
  font-family: Poppins;
  width: 30%;
  max-width: 450px;
  bottom: 125px;
  right: 100px;
  position: absolute;
}

.mainContainer {
  background-color: white;
  /* padding: 25px; */
  height: 45vh;
  overflow-y: auto;
  font-family: Poppins;
  position: relative;
  width: 100%;
}

.mainContainer2 {
  background-color: white;
  /* padding: 25px; */
  overflow-y: auto;
  font-family: Poppins;
  position: relative;
  width: 100%;
}

.chat {
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  margin: 12px;
}

.name {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 2px;
}

.message {
  color: #4d4d4d;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 0px;
}

.time {
  color: #727271;
  font-size: 8px;
  font-weight: 400;
}

.headerChatScreen {
  padding: 25px;
  color: #727271;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #1fbfc20d;
}

.recievedMessageDiv {
  display: flex;
  gap: 12px;
  margin-bottom: 15px;
}

.sentMessageDiv {
  display: flex;
  gap: 12px;
  margin-bottom: 15px;
  justify-content: flex-end;
  align-items: center;
}

.recievedMessage {
  border-radius: 5px;
  background: rgba(114, 114, 113, 0.1);
  padding: 10px 15px;
}

.sentMessage {
  border-radius: 5px;
  background: rgba(114, 114, 113, 0.2);
  padding: 10px 15px;
}

.recievedMessage p {
  color: #727271;
  font-size: 10px;
  font-weight: 400;
}

.inputElement {
  width: 410px;
  height: 48px;
  border-radius: 25px;
  background: #f1f1f1;
  position: absolute;
  display: flex;
  padding-right: 10px;
  align-items: center;
}

.inputElement input {
  width: 50px;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
}
/*----------------singe page chat------------*/
.petdetailspopupoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.lostAndFoundChats {
  background: white;
  /* padding: 20px; */
  width: 450px;
  text-align: center;
  position: relative;
  width: 572px;
  height: 476px;
  gap: 0px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
}
.LostAndFoundSingleChathearder {
  width: 552px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #66666626;
  padding: 0 15px;
  margin: 10px;
}

.LostAndFondsingleChatclose {
  position: absolute;
  top: 35px;
  left: 25px;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
}
.chatimg {
  width: 35.26px !important;
  height: 15px !important;
  padding-right: 10px;
}
.SingleChatName {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.SingleChatOnline {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.SingleChatChatmenuDropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  width: 120px;
}

.SingleChatChatmenuDropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.SingleChatChatmenuDropdown ul li {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.SingleChatChatmenuDropdown li:hover {
  background-color: #f5f5f5;
}

.SingleChatChatBody {
  margin: 20px;
}
.SingleChatChatbodyDIv {
  width: auto;
  height: auto;
  gap: 0px;
  border-radius: 5px;
  background-color: #7272711a;
  margin: 0px 10px 10px 10px;
  padding: 5px 8px 5px 15px;
  text-align: left;
}
.SingleChatChatmsgs {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.SingleChatChatbodyDIv {
  width: auto;
  height: auto;
  gap: 0px;
  border-radius: 5px;
  background-color: #7272711a;
  margin: 0px 10px 10px 10px;
  padding: 5px 8px 5px 15px;
  text-align: left;
}

.inputElement {
  width: 532px;
  height: 48px;
  border-radius: 25px;
  background: #f1f1f1;
  position: fixed;
  top: 73%;
  display: flex;
  margin: 15px;
  padding-right: 10px;
  align-items: center;
}

.inputElement input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
}

.SingleChatChatBody {
  display: flex;
  flex-direction: column-reverse; /* Ensures the first message appears at the bottom */
}

.messageBubble {
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 10px;
  max-width: 70%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.messageBubble {
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 10px;
  max-width: 70%;
  position: relative;
}

.messageBubble.sent {
  background-color: #007bff; /* Change color for sent messages */
  color: white; /* Text color for sent messages */
}

.messageBubble.received {
  background-color: #e0e0e0; /* Change color for received messages */
  color: black; /* Text color for received messages */
}
.SingleChatChatmsgs {
  margin: 0;
}

.messageTime {
  font-size: 0.8rem;
  color: #888;
  position: absolute;
  bottom: -18px;
  right: 5px;
}

.profilePic {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.inputElement {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.inputElement input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.inputElement div {
  cursor: pointer;
}
.chatPopupContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.lostAndFoundChatPopupBody {
  flex-grow: 1;
  max-height: calc(64.25vh - 150px);
  overflow-y: auto; /* Makes the chat area scrollable */
  padding-bottom: 60px; /* Adds space at the bottom for the fixed input */
}

.SingleChatChatBody {
  display: flex;
  flex-direction: column-reverse; /* Makes the messages appear from bottom to top */
}

.messageBubble {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  max-width: 70%;
  position: relative;
}

.SingleChatChatmsgs {
  margin: 0;
}

.messageTime {
  font-size: 8px;
  color: #888;
  position: absolute;
  bottom: -15px;
  right: 0px;
}

.profilePic {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.inputContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  width: 96%;
  /* padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.inputContainer input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.inputContainer div {
  cursor: pointer;
}

.ldsspinner,
.ldsspinner div,
.ldsspinner div:after {
  box-sizing: border-box;
}
.ldsspinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.ldsspinner div {
  transform-origin: 10px 10px;
  animation: ldsspinner 1.2s linear infinite;
}
.ldsspinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2px; /* Position the arms closer to the center */
  left: 9px; /* Center the arms horizontally */
  width: 2px; /* Width of the arms */
  height: 6px;
  border-radius: 20%;
  background: currentColor;
}
.ldsspinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.ldsspinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.ldsspinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.ldsspinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.ldsspinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.ldsspinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.ldsspinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.ldsspinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.ldsspinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.ldsspinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.ldsspinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.ldsspinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes ldsspinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
