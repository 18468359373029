.no-scroll {
    overflow: hidden;
  }

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1000;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #1FBFC20D;
}

.header p {
    font-size: 18px;
    font-weight: 500;
    color: #121212;
}

.popupContainer {
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    overflow: hidden;
    height: auto;
    /* max-height: 90%;
    overflow-y: auto; */
    font-family: Poppins;
    width: 30%;
    max-width: 450px;
    bottom: 125px;
    right: 100px;
    position: absolute;
  }

.mainContainer {
    background-color: white;
    padding: 25px;
    height: 35vh; 
    overflow-y: auto; 
    font-family: Poppins;
    position: relative;
    width: 100%;
}

.chat {
    padding: 25px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
}

.name{
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
}

.message{
    color: #4D4D4D;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0px;
}

.time {
    color: #727271;
    font-size: 6px;
    font-weight: 400;
}

.headerChatScreen {
    padding: 25px;
    color: #727271;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #1FBFC20D;
}

.recievedMessageDiv {
    display: flex;
    gap: 12px;
    margin-bottom: 15px;
}

.sentMessageDiv {
    display: flex;
    gap: 12px;
    margin-bottom: 15px;
    justify-content: flex-end;
    align-items: center;
}

.recievedMessage {
    border-radius: 5px;
    background: rgba(114, 114, 113, 0.10);
    padding: 10px 15px;
}

.sentMessage {
    border-radius: 5px;
    background: rgba(114, 114, 113, 0.20);
    padding: 10px 15px;
}

.recievedMessage p {
    color: #727271;
    font-size: 10px;
    font-weight: 400;
}

.inputElement {
    width: 410px;
    height: 48px;
    border-radius: 25px;
    background: #F1F1F1;
    position: fixed;
    display: flex;
    padding-right: 10px;
    align-items: center;
}

.inputElement input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
}
