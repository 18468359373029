.overviewSection{
    width: 59vw;
    background:#EFF5F5;
}


.appointmentsSection{
    width: 19vw;
    background: #D6E4E5;
padding: 10px;

}

.statsSection{
    padding: .5rem;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    }

.chartSection{
    width: 40%;
    padding: 9px 0px;
}

.statPercent{
    
    background: lightgreen;
    padding: .125rem .75rem;
    border-radius: 25px;
}

.statImage{
    background: blue;
    padding: .25rem;
    color: white;
    height: 3rem;
    width: 3rem;
    border-radius: 25%;
}

.subTitle{
    display: flex;
    justify-content: space-between;
    font-size: .9rem;
    margin-bottom: 0;
}



    
    .statItem{
        flex-grow: 1;
        flex-basis: 200px;
    }

    .neg{
    background: lightcoral;
        
    }


    .patientTableScrollStyle::-webkit-scrollbar {
        width: 6px; 
      }
      
    
      .patientTableScrollStyle::-webkit-scrollbar-track {
        background: #005555; 
      }
    
      .patientTableScrollStyle::-webkit-scrollbar-thumb {
        background: white; 
        border-radius: 3px;
      }


      .tableTitles{
        color: white !important;
        white-space: pre-line;
      }
.overviewSection2{
    display: flex;
    gap: 10px;
}

      .visitorsSection, .appointmentStatsSection{
        width: 49%;
      }