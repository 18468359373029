.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-row {
    display: flex;
    justify-content: center;
  }
  
  .service-card-page {
    width: 300px;
    height: 300px;
    margin: 10px;
    border: 1px solid #ccc;
    transition: transform 0.3s ease;
    padding: 15px;
  }
  
  .service-card-page:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-body {
    text-align: center;
    padding: 10px;
  }
  