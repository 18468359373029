.hero {
    position: relative;
    height: 100vh;
    /* min-height: 600px; */
    width: 100%;
    color: #fff;
    margin-top: 70px;
  }

  .heroBackground {
    position: absolute;
    inset: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(0.5);
  }
    
  .heroDiv {
    position: relative;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .heroDiv h1 {
    font-size: 80px;
    font-weight: 600;
    font-family: Poppins;
    margin-bottom: 40px;
  }

  .heroDiv p {
    font-size: 30px;
    font-weight: 400;
    font-family: Poppins;
    margin-bottom: 60px;
  }

  .heroDiv button {
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    height: 40px;
    width: 150px;
    border: 1px solid #FFFFFF;
  }

.PetMenuDiv {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.petMenuDiv2 {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.petImage {
  width: 100%;
  height: auto;
  max-height: 170px;
  max-width: 170px;
  border: 3px solid #F6F6F6;
  border-radius: 20px;
  margin-bottom: 20px;
}

.petMenuButton {
  color: #131313;
  font-size: 20px;
  font-weight: 600;
}


.connectMenu {
  width: 75%;
  background-color: #1FBFC2;
  border-radius: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: Poppins;
}

.connectMenu2 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.connectMenu2 h1 {
  font-size: 26px;
  font-size: 600;
  margin-bottom: 50px;
}

.connectMenu2 p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.connectMenu2 button {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  height: 40px;
  width: 150px;
  border: 1px solid #FFFFFF;
  margin-top: 30px;
}

.info {
  width: 75%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.infoItem {
  width: 30%;
  background-color: #F6F9FE;
  border-radius: 25px;
  padding: 30px;
  height: 220px;
  margin-bottom: 35px;
  font-family: Poppins;
}

.infoItem h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.infoItem p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #727271;
}

.hero2 {
  position: relative;
  height: 40vh;
  min-height: 600px;
  width: 100%;
  color: #fff;
  /* padding-top: 80px; */
}

.heroBackground2 {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.3);
}
  
.heroDiv2 {
  position: relative;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 60%;
}

.heroDiv2 h1 {
  font-size: 32px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 40px;
}

.heroDiv2 p {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  margin-bottom: 60px;
}

.faq {
  background-color: #F6F6F6;
  padding-top: 100px;
  text-align: center;
  padding-bottom: 80px;
}

.faq h1 {
  font-family: poppins;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 60px;
}

.faqDiv {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 25px;
  text-align: left;
  cursor: pointer;
  font-family: Poppins;
}

.faqDivQuestion {
  font-size: 16px;
  font-weight: 600;
  color: #141414;
  font-family: Poppins;
  margin-bottom: 0px;
  
}

.answerTitle {
  color: #727271;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
}

.answerText {
  color: #727271;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}

.answersDiv {
  margin-top: 30px;
}

@media (max-width: 480px) {

  .connectMenu {
    width: 90%;
  }

  .infoItem {
    width: 100%;
  }

  .hero {
    height: 30vh;
  }

  .heroDiv h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .heroDiv p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .heroDiv button {
    font-size: 8px;
    font-family: Poppins;
    font-weight: 400;
    height: 20px;
    width: 80px;
    border: 1px solid #FFFFFF;
  }

  .PetMenuDiv {
    margin: 10px;
    height: 30vh;
  }

  .petMenuDiv2 {
    width: 90%;
  }

  .petImage {
    width: 100%;
    max-height: 70px;
    max-width: 70px;
    margin-bottom: 10px;
  }

  .petMenuButton {
    font-size: 10px;
  }

  .hero2 {
    height: 30vh;
  }

  .faq h1 {
    font-size: 25px;
  }
  
}

/* Tinder HomePage  */

.TinderHomePage {
  width: 100%;
  margin-top: 70px;
  background-color: #F6F9FE;
  padding-bottom: 80px;
  position: relative;
  height: auto;
  /* overflow-y: auto; */
}

.profileCardDiv {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
}

.petProfileCard {
  width: 30%;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  margin: 10px;
}

.petProfileCard img{
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 210px;
}

.petProfileCard2 {
  padding: 25px;
  padding-top: 30px;
  font-family: Poppins;;
}

.petProfileCard2 h1{
  font-weight: 600;
  font-size: 20px;
  color: #727271;
}

.petProfileCard2 p{
  font-weight: 400;
  font-size: 10px;
  color: #727271;
  margin-bottom: 10px;
}

.petProfileCard2 button{
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  width: 100%;
  color: #FFFFFF;
  background-color: #F5790C;
}

.progressBar1 {
  width: 100%;
  height: 5px;
  background-color: #D9D9D9;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.StatusPill {
  width: 60px;
  background-color: #1FBFC21A;
  overflow: visible;
  position: absolute;
  top: -15px;
  left: 35%;
  text-align: center;
  padding: 2px 7px;
  cursor: pointer;
}
.StatusPill.completedpetpofile {
  width: 60px;
  background-color: green;
  overflow: visible;
  position: absolute;
  top: -15px;
  left: 35%;
  text-align: center;
  padding: 2px 7px;
  cursor: pointer;
}

.StatusPill p {
  color: #1FBFC2;
font-family: Poppins;
font-size: 6px;
font-weight: 400;
margin: 0px;
}

.addCard {
  width: 30%;
  background-color: white;
  border-radius: 5px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  margin-left: 10px;
  color: #A6ACB8;
}

.addButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #A6ACB8;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
}

.addButtonDiv p{
  margin: 0px;
  font-size: 70px;
  font-weight: 100;
  color: #A6ACB8;
  margin-bottom: 15px;
  margin-bottom: 20px;
}

.warningDiv {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  /* margin-bottom: 80px; */
  color: #BF0C0C;
  font-family: Poppins;
  background-color: #FFFFFF;
  padding: 50px;
}

.warningDiv h1{
  font-size: 15px;
  font-weight: 600;
}

.warningDiv p{
  font-size: 15px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .petProfileCard {
    width: 100%;
  }

  .addCard {
    width: 100%;
  }
}


/* Tinder Matches */

.matchesHeaderDiv {
  padding: 25px 100px;
  background-color: #1FBFC2;
  color: #FFFFFF;
  font-family: Poppins;
}

.pillsDiv {
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.25);
  padding: 10px 25px;
}

.pillsDiv p{
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.chatButton {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: #F5790C; */
  cursor: pointer;
  position: fixed;
  bottom: 10%;
  right: 6%;
}

.profileCardDiv1 {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 15px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
}

.TinderHomePage1 {
  width: 100%;
  background-color: #F6F9FE;
  padding-bottom: 80px;
  position: relative;
  height: auto;
  /* overflow-y: auto; */
}

.locationInput {
  height: 1px;
  font-size: 12px;
}


/* Tinder login modal  */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: background 0.3s ease-in-out;
}

.modalContent {
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  width: 80%;
  max-width: 450px;
  color: #fff; 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15); 
  transform: translateY(-30px);
  animation: fadeInUp 0.5s forwards; 
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.closeButton, .confirmButton {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.closeButton {
  background: #333;
  color: #fff;
  border: 2px solid #fff;
}

.confirmButton {
  background: #4caf50;
  color: white;
  border: 2px solid #4caf50;
}

.closeButton:hover, .confirmButton:hover {
  transform: translateY(-4px); 
}

.closeButton:focus, .confirmButton:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.modalContentText{
  color: #141414;
  font-weight: 900;
  font-style: bold;
  font-size: 16px;
}

.disabledButton {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}



.hiddenCard {
  opacity: 0.5;
  pointer-events: none;
  position: relative;
}

.hiddenBand {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 10;
}



