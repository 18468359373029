body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  /* h1, h2, h3, p {
    margin: 0;
  } */
  
  /* Main Header */
  .mainHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    padding: 0 10%;
    background-color: #f9f9f9;
  }
  
  .leftsec {
    max-width: 50%;
  }
  
  .hero-title {
    font-size: 4rem;
    margin: 10px 0;
  }
  
  .hero-description {
    font-size: 1.2rem;
    width: 80%;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .button-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .button_one, .button_two {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ffcc00;
    border: none;
    transition: background-color 0.3s;
  }
  
  .button_one:hover, .button_two:hover {
    background-color: #ff9900;
  }
  
  /* Services Section */
  .services {
    padding: 50px 10%;
    background-color: #fff;
    text-align: center;
  }
  
  .services-head {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .flip-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .flip-card {
    perspective: 1000px;
    width: 250px;
    height: 300px;
  }
  
  .flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .flip-card-front {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .flip-card-back {
    background-color: rgba(0, 0, 0, 0.7);
    transform: rotateY(180deg);
  }
  
  .flip-card-description {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* About Us Section */
  .about-us-section {
    padding: 50px 10%;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .abthead {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .abttxt {
    font-size: 1rem;
    margin-top: 20px;
    line-height: 1.5;
  }
  
  .petloveimg {
    width: 30px;
    vertical-align: middle;
  }
  
  /* Dynamic Question Section */
  .question-sec {
    padding: 30px 10%;
    background-color: #fff;
  }
  
  .dynamic-question {
    font-size: 2rem;
    font-weight: 400;
    padding: 20px 0;
    transition: color 0.3s;
  }
  
  /* Contact Section */
  .contact-head {
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .contact-head h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .contact-head p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 5px 0;
  }
  
  .contact-head b {
    font-weight: bold;
  }