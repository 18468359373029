@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
}

.signmainHeader {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
}

.containerbody {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
  margin: auto;
  margin-top: 50px;
  height: 90vh;
}

.signuph1 {
  font-weight: bold;
  margin: 0;
}

.signuph2 {
  text-align: center;
}

.signupp {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  color:black
}

span {
  font-size: 12px;
}

.a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.buttons {
  border-radius: 10px;
  border: 1px solid #0c00f2;
  background-color: #000c1c;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.buttons:active {
  transform: scale(0.95);
}

.buttons:focus {
  outline: none;
}

.buttons.ghost {
  /* background-color: transparent; */
  background: linear-gradient(45deg, #ee5757, #fe904b);
  border-color: #ffffff;
}

.i1 {
  color: #3c3737;
  font-size: 22px;
  padding-top: 2px;
}

.i2 {
  color: #3c3737;
  font-size: 19px;
  padding-top: 2px;
}

.signform {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.textinput {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.containeronly {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.containeronly.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.containeronly.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

.errortext {
  color: red;
  margin-top: -6px;
  /* margin-left: -183px; */
  text-align: left !important;
}

.errortextmargin {
  /* margin-left: -160px; */
}

.eye-icon-stl {
  color: grey;
  font-size: 24px;
  margin-top: 37px;
}
@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.containeronly.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  /* background: #ff416c; */
  background: #c7e0f5;
  /* background: linear-gradient(to right, #0c00f2, #000c1c); */
  /* background: linear-gradient(to bottom, #a8d3f8, #fff); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  /* color: #ffffff; */
  color: #18345e;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.containeronly.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.containeronly.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.containeronly.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container .a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
