.my-post-header{
    width: 1920px;
height: 64px;
position: relative;
top: 2px;
left: 1px;
gap: 0px;
background: #1FBFC2;


}
.my-post-header-p{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 33.6px;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    overflow: hidden;
color: #FFFFFF;
margin: 10px 0px 0px 10px;

}
.my-post-body{
  margin: 52px 156px 127px 213px;
   

}
#lost-and-found-post{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0px 0px 17px 26px;

}
.my-post-body-content{
  width: 101.5%;
  margin-left: 24px;
height: 167px;
top: 748px;
left: 311px;
gap: 0px;
background: #ECFDFD;
display: flex;

}
#lost-and-found-post-youPet{
    font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-bottom: 20px;


}
.lost-and-post-button{
    width: 269px;
height: 56px;
top: 834px;
left: 826px;
gap: 0px;
background: #F5790C;
color:  #FFFFFF;
font-family: Poppins;
font-size: 15px;
font-weight: 500;
line-height: 22.5px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
padding-top: 0px;
margin: 0px 0px 0px 25px;
}
/*--------------------------------------------------------Lost-and-found Popup-------------------------------------------*/

.lost-and-found-post-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lost-and-found-post-popup-content {
    background: white;
    /* padding: 20px; */
    width: 400px;
    text-align: center;
    position: relative;
    width: 572px;
height: 349px;
gap: 0px;

  }
  
  .lost-and-found-post-popup-close {
    position: absolute;
    top: 20px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  .lost-and-found-popup-hearder{
    width: 572px;
height: 60px;
gap: 0px;
background: #1FBFC20D;
margin: 0px;
box-shadow: 0px 0px 10px 0px #0000001A;

  }
  .lost-and-found-popup-select-lost{
    width: 179px;
    height: 22px;

left: 50px;
gap: 0px;

color: #121212;
padding: 13px 0px 0px 5px;

  }
  .lost-and-found-popup-body{
  margin: 50px 10px 0px 100px;
  display: flex;
  }
  .lost-and-found-pop-lost{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
justify-content: center;
text-align: center;
  }

  /*----------------------------------------Lost-and-found Lost popup-----------------------------------------*/

.lost-and-found-Lost-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
    box-shadow: 0px 0px 10px 0px #0000001A;

  }
  
 
  .lost-and-found-Lost-popup-content {
    background: white;
    width: 900px;
    max-height: 60vh;
    position: relative;
    top: 40px;
    left: 300px;
    text-align: center;
    overflow-y: auto;
    margin-bottom: 100px;
  }
  
  .lost-and-found-Lost-popup-close {
    position: absolute;
    top: 20px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  
  .i {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    width: 710px;
    height: 162px;
    position: relative;
    margin-left: 10px;
    left: 380px;
    top: 50px;
    position: relative;
  }
  
  .lost-and-found-popup-rightimg,
  .lost-and-found-popup-leftimg {
    width: 150px;
    height: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lost-and-found-popup-middle-text {
    width: auto;
    text-align: center;
    flex-grow: 1;
    margin: 0 20px;
    font-family: Poppins, sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    color: #FFFFFF;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .lost-and-found-td{
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
/* padding: 8px; */

  }
  .lost-and-found-popup-button2 {
    width: 164px;
    height: 50px;
    margin: 15px  auto; 
    background-color: #F5790C;
    color: #FFFFFF;
    font-size: 18px;
    display: flex; 
    justify-content: center;
    align-items: center;
    border: none; 
}
input[type="file"]::file-selector-button {
    background-color: #F3F3F380;
    color: #F3F3F380;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
}
input[type='file'] { font-size: 0; }
::file-selector-button { font-size: initial; 
}
.lost-and-post-mypost-msg{
  position: fixed;
  bottom:  60px; 
  right: 40px; 
 
}
.lost-and-found-found-popup-hearder{
  width: 100%;
height: 60px;
gap: 0px;
background: #1FBFC20D;
margin: 0px;
box-shadow: 0px 0px 10px 0px #0000001A;

}

/*---------------------------------------pet detials------------*/
.lost-and-found-post-petdetails-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1000;
}
.lost-and-found-post-petdetails-content {
  background: white;
  width: 650px;
  height: 521.5px;
  text-align: center;
  position: relative;
  gap: 0px;
  
  /* position: relative;
  top: -150px; 
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.lost-and-found-mypost-petDetails-hearder{
  width: 650px;
  height: 275px;
  gap: 0px;
  


}
.lost-and-found-mypetdetails {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  padding-left: 50px;
  padding-top: 20px;
}

.lost-and-found-my-petdetails-petname {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  color: #727271;
  margin: 0;
}

.lost-and-found-my-petdetails-petId-petGender,
.lost-and-found-my-petdetails-Location,
.lost-and-found-my-petdetails-pet-lostorFound {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  color: #727271;
  margin: 10px 0;
}




.lost-and-found-my-petdetails-pet-lostorFounddate{
  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 19.2px;
text-align: justified;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;
}
.my-post-petdatiles-post{
  background-color: #F5790C;
  border-radius: 15px 0px 0px 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}
.my-post-petdatiles-post2{
  background-color: #F5790C;
  border-radius: 0px 15px 0px 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}
.crossButton {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;;
}
.petdetials-Reunited{
width: 82px;
top: 641px;
left: 475px;
gap: 0px;
border-radius: 2px 0px 0px 0px;
background: #E1F4F8;
color: #1FBFC2;
font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 22.4px;
text-align: justified;
text-underline-position: from-font;
text-decoration-skip-ink: none;
text-align: center;
padding-top: 5px;
height: 33px;
margin-bottom: 10px;

}

/*-----------------------changestatus----------------------------*/
.lost-and-found-post-changes-close {
  position: absolute;
  top: 20px;
  left: 25px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.changes-page-p1 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justify; 
  color: #727271;
  word-break: break-word; 
  white-space: normal; 
}

.lost-and-found-popup-body2{
  margin: 20px 40px 40px 40px;
  display: flex;
  }
  .changes-page-p2{
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
  }
  .changeStatus-button {
    width: 164px;
    height: 50px;
    background-color: #F5790C;
    color: #FFFFFF;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center; 
    border: 1px solid transparent; 
    cursor: pointer; 
    margin: 50px 0px 0px 150px;
  }
  .lost-and-found-post-changestatus-content {
    background: white;
    /* padding: 20px; */
    width: 400px;
    text-align: center;
    position: relative;
    width: 572px;
height: 500px;
gap: 0px;

  }
  .changeStatus-warring{
    width: auto;
height: 104px;
top: 384px;
left: 50px;
gap: 0px;
background-color: #F8F8F8;
display: flex;

margin: 30px 0px 0px 0px;
  }
  .changeStatus-warring-text{
    font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 19.2px;
text-align: justified;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;
margin: 20px;
text-align: left;
  }
  .changeStatus-warring-img{
    width: 60px;
    padding: 10px;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: -20px;
  }
  .changes-status-dropdown{
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
padding-left:10px !important;
  }
  
  /*---------------------------------------------Changes Status page 2-------------------------------------*/
  .changesstatus2-div1{
    font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;
margin: 0px 0px 10px 0px;

  }
  .lost-and-found-changes2-body{
    margin: 10px 21px 10px 35px;
   
    }
    .lost-and-found-change2-popup-content {
      background: white;
      /* padding: 20px; */
      width: 400px;
      text-align: center;
      position: relative;
      width: 572px;
  height: 550px;
  gap: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  
    }
    .changestatus2-p{
      font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;


    }
    .changestatuspage2-p2{
      font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;
margin-bottom: 10px;

    }
    .changestatus-div2{
      font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;
margin-bottom: 10px;
    }
  .changestatus2-p2{
    font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #727271;
  }
  .changestatusbutton {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 164px;
    height: 50px;
    top: 982px;
    left: 243px;
    border: 1px solid transparent; 
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: center; 
    text-decoration: none;
    background-color: #F5790C;
    color: #FFFFFF;
  }  
  .parent-container-changestatus {
    display: flex;
    justify-content: center;
    align-items: center; 
  margin-top: 20px;
  margin-bottom: 20px;
  }
  .changesstatus2-textbox{
    
text-underline-position: from-font;
text-decoration-skip-ink: none;
background-color: #F3F3F380;
border-color: #D9D9D9;
height: 40px;
margin-bottom: 10px;
color: #727271;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  .changes-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;

  }

  .legs-container {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Optional spacing between the images */
  }

  

  /*--------------------------------Edit Pet Details-----------------------------*/

  .lost-and-found-popup-edit-body{
    margin: 25px 43px 19px 43px;

  }
  .edit-pet-details-lable1 {
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: start; 
    display: block;
    margin-bottom: 8px; 
}
.edit-chnges-page{
  border-color: #D9D9D9;
background-color: #F3F3F380;

color: #727271;
text-align: justify;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 19.2px */
}
.edit-changes-button{
  width: 164px;
height: 50px;
flex-shrink: 0;
width: 164px;
height: 50px;
flex-shrink: 0;
border: 1px solid #F5790C;
background: #F5790C;
color: #FFFFFF;
color: #FFF;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 25.2px */
justify-content: center;
align-items: center;

}

.lost-and-found-post-edit-popup-content {
  background: white;
  /* padding: 20px; */
  width: 400px;
  text-align: center;
  position: relative;
  width: 572px;
  height: 486px;
  gap: 0px;
}
.lost-and-found-button-container{
  margin-top: 20px;
}
.lost-and-found-post-edit-found-popup-content {
  background: white;
  /* padding: 20px; */
  width: 400px;
  text-align: center;
  position: relative;
  width: 572px;
  height: 334px;
  gap: 0px;
}
.lost-and-found-post-petdetails-content2 {
  background: white;
  width: 650px;
  height: 490.5px;
  text-align: center;
  position: relative;
  gap: 0px;
}