.blog-main-container {
  width: 90%;
  margin: 0 auto;
  /* background-color: aqua; */
}

/* For tablets and small desktops */
@media (min-width: 768px) and (max-width: 1199px) {
  .blog-main-container {
    max-width: 720px;
  }
}

/* For mobile phones */
@media (max-width: 767px) {
  .blog-main-container {
    max-width: 100%;
    padding: 0 15px;
  }
}

.blog-card-link {
  text-decoration: none;
  color: none;
}

.blog-flex-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.blog-image-container img {
  width: 850px;
  height: 500px;
  border-radius: 5px 0px 0px 0px;
  /* opacity: 0px; */
  object-fit: cover;
}

/* For 14-inch screens */
@media (min-width: 1280px) and (max-width: 1366px) {
  .blog-image-container img {
    width: 750px;
    height: 450px;
    border-radius: 10px;
  }
}

/* For larger screens (e.g., 15-inch or more) */
@media (min-width: 1367px) {
  .blog-image-container img {
    width: 850px;
    height: 500px;
  }
}

/* For tablets and smaller desktops */
@media (min-width: 768px) and (max-width: 1279px) {
  .blog-image-container img {
    width: 100%;
    max-height: 400px;
    border-radius: 5px;
  }
}

/* For mobile phones */
@media (max-width: 767px) {
  .blog-image-container img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
}

.blog-content-container {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

#blog-main-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
  margin-top: 15px;
}

.blog-main-sub-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
}

.blog-item-container-categories {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

#blog-item {
  margin: 0;
}

.blog-rignt-main-content-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: #121212;
  margin-top: 15px;
  margin: 0;
}

#blog-rignt-sub-content {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.20000000298023224px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  height: 30px;
}

/* @media (min-width: 1280px) and (max-width: 1366px) {
  #blog-rignt-sub-content {
    font-size: 13px;
    line-height: 32px;
    height: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  #blog-rignt-sub-content {
    font-size: 16px;
    line-height: 28px;
    height: 300px;
  }
} */

/* For mobile phones (max-width: 767px) */
/* @media (max-width: 767px) {
  #blog-rignt-sub-content {
    font-size: 14px; /* Smaller text on mobile screens */
/* line-height: 24px;
    height: auto; 
    overflow: visible; */

/* For very large screens (larger than 1366px) */
@media (min-width: 1367px) {
  #blog-rignt-sub-content {
    font-size: 20px;
    line-height: 36px;
    height: 380px; /* Larger height for bigger screens */
  }
}

.blog-item-container {
  display: flex;
  gap: 10px;
}

#blog-item {
  border-right: 1px solid #666666;
  padding-right: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666;
}

#blog-rignt-main-content {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.blog-right-btn {
  width: 187px;
  height: 50px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  background-color: #f5790c;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: auto;
}

.blog-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  margin-top: 30px;
}

.blog-card {
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  background-color: #ffffff;
  gap: 20px;
}

.blog-card-image {
  object-fit: cover;
  /* object-position: 80% 100%; */
  filter: brightness(0.9) contrast(1.1);
  transition: transform 0.3s ease, filter 0.3s ease;
  width: 450px;
  height: 200px;
  border-radius: 5px 5px 0px 0px;
}

.blog-card-image:hover {
  transform: scale(1.05);
  filter: brightness(1) contrast(1.2);
}

.blog-card-image-blog {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 100%;
}

.blog-card-title {
  padding-top: 15px;
  padding-left: 10px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.blog-card-subtitle {
  font-size: 14px;
  color: #666;
  padding-left: 10px;
  margin-bottom: 10px;
}

.blog-card-view-all-btn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: center;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
  margin: 0 45%;
}
.blog-card-view-all-btn:hover {
  text-decoration: underline;
}

#blog-latest-pet-news {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

.blog-latest-pet-news-color {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.animal-button {
  width: 103px;
  height: 50px;
  border-radius: 10px 0px 0px 0px;
  /* opacity: 0px; */
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #e1f4f8;
}

.blog-particular-container {
  display: flex;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 1024px) {
  .blog-particular-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 768px) {
  .blog-particular-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 480px) {
  .blog-particular-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.blog-particular-left-content {
  flex: 7;
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  /* border-right: 1px solid #d9d9d9; */
}

.blog-particular-right-content {
  flex: 3;
  padding: 40px;
  margin-top: 35px;
  /* background-color: #f5f5f5; */
}

.blog-particular-search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
}

.blog-search-container {
  position: relative;
}

.blog-particular-search-input::placeholder {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.20000000298023224px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 5px;
}

.blog-particular-search-input:focus {
  border: #727271;
}

.blog-search-icon {
  position: absolute;
  right: 10px;
  top: 30%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
}

.blog-particular-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.blog-particular-title-date {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666;
}

.blog-particular-custom-hr {
  border: none;
  border-top: 2px solid #d9d9d9;
}

.blog-particular-header-top {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.blog-particular-header-backBtn {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000000d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.blog-particular-share-post {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
}

.blog-particular-share-post-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  letter-spacing: 0.20000000298023224px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.blog-particular-share-post-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* seperate categories css */

.blog-card-seperate-categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* padding: 20px; */
  padding-bottom: 15px;
  margin-top: 20px;
}

.all-sepetate-contaner-blog {
  margin-left: 17px;
}

.Latest-Pet-News {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.Latest-Pet-News-color {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  color: #f5790c;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.blog-card-seperate {
  background-color: #ffffff;
  width: 450px;
}

.blog-card-seperate-image {
  object-fit: cover;
  /* object-position: 80% 100%; */
  filter: brightness(0.9) contrast(1.1);
  width: 450px;
  height: 200px;
  border-radius: 5px 5px 0px 0px;
}

.blog-card-seperate-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.blog-seperate-content {
  padding: 10px;
}
.blog-card-details-categories {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px;
}

.blog-card-categories {
  font-size: 14px;
  color: #555;
  flex: 1;
}

.blog-card-date {
  font-size: 14px;
  color: #777;
  text-align: right;
  white-space: nowrap;
}

.recent-posts {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
}

.recent-post-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #f0f0f0;
  margin-bottom: 15px;
  cursor: pointer;
  height: 80px;
}

.recent-post-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.20000000298023224px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Base styles for the card */
.card {
  margin: 10px;
  width: 100%;
  height: 200px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

/* Shimmer Effect - the animation */
@keyframes shine {
  100% {
    background-position-x: -200%;
  }
}

/* Loading state for the card */
.card.is-loading .image,
.card.is-loading h2,
.card.is-loading p {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: shine 1.5s linear infinite;
}

.card.is-loading .image {
  /* height: 200px; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card.is-loading h2 {
  height: 30px;
}

.card.is-loading p {
  height: 70px;
}

/* .blog-item-man-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
} */

@media (min-width: 576px) {
  .blog-item-man-page {
    grid-template-columns: repeat(3, 1fr);
  }
}
