.about {
    width: 100%;
    max-width: 1440px;
    background: #f6f9fe;
  }
  
  .aboutContent {
    display: flex;
    gap: 20px;
    padding: 70px 150px 0px 150px;
  }
  
  .imageColumn {
    width: 46%;
  }
  
  .clinicImage {
    aspect-ratio: 0.75;
    object-fit: cover;
    width: 100%;
    border-radius: 25px;
  }
  
  .textColumn {
    width: 54%;
    padding: 0 20px;
    font-size: 18px;
    color: #131313;
  }
  
  .sectionTitle {
    font: 500 40px Poppins, sans-serif;
    margin-bottom: 24px;
  }
  
  .description {
    font-family: Poppins, sans-serif;
    color: #727271;
    line-height: 25px;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
  }
  
  .servicesTitle {
    font: 500 32px Poppins, sans-serif;
    margin-bottom: 24px;
  }
  
  .servicesList {
    font-family: Poppins, sans-serif;
    color: #727271;
    line-height: 27px;
    padding-left: 20px;
    margin-bottom: 24px;
  }
  
  .appointmentButton {
    font-family: Poppins, sans-serif;
    background-color: #f5790c;
    color: #fff;
    padding: 16px 48px;
    border: 1px solid #f5790c;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 24px;
  }
  
  @media (max-width: 991px) {
    .about {
      max-width: 100%;
      margin-top: 40px;
    }
  
    .aboutContent {
      flex-direction: column;
      padding: 24px;
    }
  
    .imageColumn,
    .textColumn {
      width: 100%;
    }
  
    .clinicImage {
      margin-top: 40px;
    }
  
    .textColumn {
      margin-top: 40px;
    }
  
    .sectionTitle,
    .description,
    .servicesTitle,
    .servicesList,
    .appointmentButton {
      margin-top: 25px;
    }
  
    .appointmentButton {
      padding: 16px 20px;
    }
  }