.graphSection{
display: flex;
flex-wrap: wrap;
gap: 10px;
}

.item{

    flex-grow: 1;
    flex-basis: 200px;
}


.tableScrollStyle::-webkit-scrollbar {
    width: 6px; 
  }
  

  .tableScrollStyle::-webkit-scrollbar-track {
    background: #005555; 
  }

  .tableScrollStyle::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 3px;
  }