.services {
  margin-top: 30px;
  padding-bottom: 50px;
}

.sectionTitle {
  color: #131313;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 79px;
  margin-left: 110px;
}

.servicesGrid {
  display: flex;
  gap: 3px;
  height: 90vh;
}

.serviceCard {
  position: relative;
  overflow: hidden;
}

.serviceImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}
img[data-src] {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.serviceContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
}

.serviceIcon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.serviceTitle {
  font: 500 34px Work Sans, sans-serif;
}

.serviceDescription {
  font: 400 13px Work Sans, sans-serif;
  margin-bottom: 20px;
}
.serviceButton {
  position: absolute;
  top: 50%;
  left: 10%;
}

.readMoreButton {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 10px 20px;
  font: 400 18px Poppins, sans-serif;
  cursor: pointer;
}

.smallServicesContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.smallServicesContainerSecondary {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.additionalServiceImage {
  width: 33.33%;
  object-fit: cover;
}
.SecondButton {
  position: absolute;
  bottom: 47%;
  z-index: 1;
  left: 78%;
}

@media (max-width: 991px) {
  .services {
    margin-top: 40px;
  }
  .sectionTitle {
    font-size: 32px;
    margin-bottom: 40px;
  }

  /* .servicesGrid {
      flex-direction: column;
    } */

  .additionalServiceImage {
    width: 100%;
  }
  .SecondButton {
    display: none;
  }
  .serviceButton {
    display: none;
  }
  .readMoreButton {
    padding: 4px 12px;
  }
}

.serviceCardBig {
  width: 49%;
  height: 100%;
  position: relative;
}

.serviceCardsGrid {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 50%;
  gap: 3px;
}

.serviceCardSmall {
  width: 49%;
  height: 50%;
  position: relative;
}

.servicesGrid {
  transition: transform 0.5s ease;
}

.slideIn {
  animation: slideIn 0.5s forwards;
}

.slideOut {
  animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.serviceCardBigTextBox {
  position: absolute;
  color: #ffffff;
  bottom: 12%;
  left: 22%;
  width: 64%;
}

.serviceCardBigTextBox h1 {
  font-size: 32px;
  font-family: Poppins;
  font-weight: 500;
}

.serviceCardBigTextBox p {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
}

.serviceCardBigTextBox button {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  height: 40px;
  width: 180px;
  border: 1px solid #ffffff;
  margin-top: 30px;
}

.serviceCardSmall p {
  position: absolute;
  color: #ffffff;
  bottom: 11%;
  left: 19%;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 500;
}
