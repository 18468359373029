.community {
    background-color: #1fbfc2;
    color: #fff;
    padding: 80px;
    text-align: center;
  }
  
  .sectionTitle {
    font: 500 40px/1.4 Poppins, sans-serif;
    margin-bottom: 79px;
  }
  
  .communityGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px;
  }
  
  .communityCard {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .communityIcon {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
  
  .communityCount {
    font: 500 36px Poppins, sans-serif;
    margin-bottom: 5px;
  }
  
  .communityLabel {
    font: 400 18px/1.67 Poppins, sans-serif;
  }
  
  @media (max-width: 991px) {
    .sectionTitle {
     font-size: 32px;
      margin-bottom: 40px;
    }
    .communityCount {
     font-size: 16px;
      margin-bottom: 5px;
    }
    .communityLabel {
     font-size: 12px;
    }
    .communityCard {

    }

    .communityIcon {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
    .community {
      padding: 40px 20px;
    }
  
    .communityGrid {
      grid-template-columns: repeat(2, 1fr);
      gap: 18px;
    }
  }