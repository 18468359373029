.radioGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .radioLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    position: relative;
    transition: color 0.3s ease; 
  }
  
  .radioInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .radioCustom {
    height: 20px;
    width: 20px;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .radioCustom::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #1FBFC2;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scale(0.8); 
  }
  .radioInput:checked + .radioCustom {
    border-color: #1FBFC2; 
    background-color: #e6f9fa; 
  }
  
  .radioInput:checked + .radioCustom::after {
    opacity: 1;
    transform: scale(1); 
  }
  
  .radioLabel:hover .radioCustom {
    border-color: #1FBFC2; 
  }
  
  .radioLabel:hover {
    color: #1FBFC2; 
  }
  