.contactBg{
    background: url('./Leaves.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(38px);
}

.formCard{
    height: 80vh;
    width: 60vw;
    background: white;
}