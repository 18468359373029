.hero {
    position: relative;
    min-height: 600px;
    width: 100%;
    color: #fff;
    padding: 279px 60px 226px 240px;
    font: 700 48px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .heroBackground {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .heroTitle {
    position: relative;
    z-index: 1;
  }
  
  @media (max-width: 1200px) {
    .hero {
      padding: 200px 40px 150px 160px;
      font-size: 40px;
    }
  }
  
  @media (max-width: 991px) {
    .hero {
      padding: 150px 30px 100px 100px;
      font-size: 36px;
    }
  }
  
  @media (max-width: 768px) {
    .hero {
      padding: 100px 20px 80px 50px;
      font-size: 32px;
      min-height: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .hero {
      padding: 164px 20px 60px 30px;
      font-size: 28px;
      min-height: 300px;
    }
  }