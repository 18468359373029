
.checkboxDiv {
    margin-bottom: 20px;
}

.checkBox {
    height: 25px;
    width: 25px;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioButton {
    height: 13px;
    width: 13px;
    border: 1px solid #616161;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:"#61677F"
  
}

.textarea {
    width: '100%';
    border: '1px solid #D9D9D9';
    padding: 15px 25px;
}

.DropDownV1Div {
    margin-bottom: 35px;
}

.DropDownV1Div p {
    color: #61677F;

    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
}

select {
    width: 100%;
    border: 1px solid #D9D9D9;
    background-color: rgba(243, 243, 243, 0.50);
    height: 50px;
    padding: 15px 25px;
    padding-top: 12px;
    font-family: Poppins;
  }

  select option:first-child {
    color: #909AA3;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
  }

  /* .inputDiv {
     margin-bottom: 35px; 
} */

.inputDiv p {
    color: #61677F;

    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
}


input {
    width: 100%;
    border: 1px solid #D9D9D9;
    background-color: rgba(243, 243, 243, 0.50);
    height: 50px;
    padding: 15px 25px;
    padding-top: 12px;
    font-family: Poppins;

}
input:focus {
    border: none; /* Add a custom border on focus */
    outline: none; /* Optional background change */
  }

.radioButtonSelected {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #616161;
}
.lostfoundSelectLost{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 30px;
/* margin-bottom: 10px; */
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #61677F;

}
.inputDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border-radius: 8px; */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  }
  .colorInputWrapper {
    background-image: url("../../assets/images/colorimg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 10px;
    height: 40px;
    
  }
  
  .colorInput {
    display: none; 
  }
  .a{
    border: 1px soild green;
  }








  /* Styling for the color box */
.colorname {
    width: 57px; /* Adjust width to fit more than two color boxes in one row */
    height: 30px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #F1F1F1;
    color: #727271;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 160%; /* 16px */
    margin: 5px; /* Margin between each color box */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  /* Styling for the 'x' (delete button) inside each color box */
  .colorname div {
    cursor: pointer;
    margin-left: 5px; /* Adds space between color name and the 'x' */
  }
  
  /* Styling for the color picker input wrapper */
  .colorInputWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .errormsg{
 
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 19.2px */
  }
.CTACPara{
    font-size:20px !important;
}
  