
  
  .fileUploadInput {
    display: none;
  }
  
  .fileUploadCustomLabel {
    display: flex;
    width: 100%;
    height: 45px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .fileUploadChoose {
    width: 40%;
    height: 100%;
    background-color: #727271;
    border:1px solid #727271;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .fileUploadFilename {
    width: 60%;
    height: 100%;
    background-color: #f3f3f3;
    color: #727271;
    display: flex;
    align-items: center;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  