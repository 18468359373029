/* Container styling */
.blog-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.blog-card-new-component {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 400;
  align-items: flex-start;
  cursor: pointer;
}

.blog-main-post-main-button {
  width: 187px;
  height: 50px;
  border: 1px solid #f5790c;
  background-color: #f5790c;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.blog-Category-date-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 22.4px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.blog-main-post-sub-dec {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.20000000298023224px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.blog-main-post-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}
.blog-Category-date-Name {
  border-right: 1px solid #555;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.blog-image img {
  width: 850px;
  height: 500px;
  border-radius: 5px 0px 0px 0px;
}

.blog-content {
  flex: 1 1 60%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
}

.blog-content h2 {
  margin: 0 0 10px;
  font-size: 1.8rem;
}

.blog-content p {
  margin: 10px 0;
}

.main-name-blog-component{
  
}

.blog-content small {
  color: #555;
  margin-top: auto; /* Push the small tag to the bottom */
}

/* Responsive styles */
@media (max-width: 768px) {
  .blog-card {
    flex-direction: column;
  }

  .blog-image,
  .blog-content {
    max-width: 100%;
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .blog-container {
    padding: 10px;
  }

  .blog-content h2 {
    font-size: 1.5rem;
  }
}
/* last 3 cards  */

.blog-cards-grid-new {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  cursor: pointer;
  margin: 50px auto;
}

.blog-card-new {
  flex: 1;
  /* max-width: 30%; */
  width: 450px;
  height: 280px;
  border-radius: 5px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.blog-content-new-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.blog-image-new img {
  object-fit: cover;
  width: 450px;
  height: 200px;
  border-radius: 5px 5px 0px 0px;
}

.blog-content-new {
  padding: 10px;
}

.blog-Category-date-container-new {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #555;
}

/* Blog for seperate categories */

.blog-cards-grid-BlogCategories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  cursor: pointer;
  margin: 0 auto;

  /* display: flex;
  justify-content: center; */
  max-width: 1200px;
}

.blog-card-new-BlogCategories {
  background: #fff;
  border-radius: 5px;
  width: 380px;
  height: 280px;
}

.blog-image-new-BlogCategories img {
  border-radius: 5px 5px 0 0;
  width: 380px;
  height: 200px;
  max-width: 100%;
  max-height: 100%;
}

.blog-content-new-BlogCategories {
  padding: 15px;
}

.blog-content-new-title-BlogCategories {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.blog-Category-date-container-new-BlogCategories p {
  display: flex;
  flex-direction: row;
  gap: 15px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

@media (max-width: 1024px) {
  .blog-cards-grid-BlogCategories {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blog-cards-grid-BlogCategories {
    grid-template-columns: 1fr;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tag-button {
  width: auto;
  height: 50px;
  border-radius: 10px 0px 0px 0px;
  background-color: #e1f4f8;
  color: #61677f;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tag-button:hover {
  background-color: #1fbfc2;
  color: white;
}

.tag-button.active {
  background-color: #1fbfc2;
  color: white;
}

.latest-Name-style {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.latest-Name-style span {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
}

/* .blog-featured-image-container {
  width: 800px; 
  height: 500px;
} */

.blog-featured-image {
  width: 100%;
  height: 400px;
  max-width: 100%;
  max-height: 100%; 
  object-fit: fill;
  
}


.blog-tages-categories-name{
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 22.4px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #666666;
}