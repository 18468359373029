.login-signmainHeader {
  /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
  background: #f6f9fe;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
}

.login-containerbody {
  background: #f6f5f7;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  /* height: 100vh; */
  /* margin: -40px 0 90px; */
  margin: auto;
  margin-top: 50px;
  height: 80vh;
  margin-bottom: 50px;
  position: relative;
}
.login-containeronly {
  background-color: #fff;
  /* border-radius: 10px; */
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  position: relative;
  /* overflow: hidden; */
  width: 868px;
  max-width: 100%;
  min-height: 480px;
 
}

.login-container {
  display: flex;
  height: 100vh;
}

/* Left and right sides equally split */
.login-left-side {
  width: 50%;

  justify-content: center;
  align-items: center;
  padding: 0px;
}
.login-zaanvar-icon {
  justify-content: center;
  margin-top: 40px;
  /* margin-left: 210px; */

  display: flex;
  align-items: center;
}
.login-welcome {
  font-size: 20px;
  margin-top: 28px;
  justify-content: center;
  text-align: center;
}
.login-l1 {
  color: #727271;
  font-size: 13px;
  text-align: center;
  margin-top: -10px;
  /* margin-left: 25px; */
}
.login-form {
  margin-top: 30px;
  margin-left: 54px;
  margin-right: 20px;
  width: 75%;
}
.login-forgotpass {
  margin-left: 126px;
  font-size: 11px;
  margin-top: 17px;
  color: blue;
}

.fixed-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 200%; /* Adjust as needed */
  height: 100%;
  z-index: 0;
  /* margin-left: 50px; */
  pointer-events: none; /* So clicks pass through to elements above */
  position: relative;
}
.login-field {
  background-color: none;
  height: 30px;
  margin-top: 0px;
  font-size: 12px;
  /* margin-bottom: 20px; */
}
.login-field::placeholder {
  font-size: 12px;
}
.error {
  color: red;
  font-size: 12px;
  /* margin-top: 4px; */
  min-height: 20px;
}


.login-button {
  background: #f5790c;
  width: 100%;
  color: white;
  font-size: 10px;
  padding: 10px;
  margin-top: 25px;
}
.login-column2 {
  width: 50%;
}
.login-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-top: 55px;
}
.signin-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-top: 40px;
}
.signin-welcome {
  font-size: 20px;
  margin-top: 22px;
  justify-content: center;
  text-align: center;
}
/* Basic checkbox styling */
.custom-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
 

  cursor: pointer;
}

/* Checkbox hover effect */
.custom-checkbox:hover {
  border-color: none;
  background-color: none;

}

/* Checkbox checked style */
.custom-checkbox:checked {
  background-color: none;
  border-color: none;
  border: none;
}

/* Checked indicator */
.custom-checkbox:checked::after {
  content: "";
  display: block;
  position: relative;
  left: 4px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: none;
  border-width: none;
}
.pwd-eye{
  justify-items: end;
  margin-top: -24px;
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;

}


@media (max-width: 768px) {
  .login-column2 {
    display: none;
  }
  .login-containeronly {
    background-color: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
    position: relative;
    /* overflow: hidden; */
    width: 793px;
    max-width: 73%;
  }
  .login-left-side {
    width: 95%;
  }
  .login-bottom {
    margin-top: 20px;
  }
  .signin-welcome {
    font-size: 20px;
    margin-top: 0px;
    justify-content: center;
    text-align: center;
  }
  .pwd-eye{
    
    left: 250px;
  }
}
@media (max-width: 320px) {
  .login-containeronly {
    width: 817px;
    max-width: 296%;
    margin-left: -96%;
  }
  .login-l1 {
    color: #727271;
    font-size: 10px;
  }
  .login-signmainHeader {
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
    /* background: #F6F9FE; */
    /* height: 1vh;  */
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    /* background-size: cover; */
    /* margin: 0; */
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* align-items: center; */
    /* padding: 0 100px; */
  }
  .login-forgotpass {
    position: relative;
    left: 0px;
    margin-left: 53px;
  }
  .login-left-side {
    width: 52vh;
  }
  .pwd-eye{
    
    left: 220px;
  }
  
}

