.bookingSteps {
    background-color: #f6f9fe;
    padding: 80px 60px;
    text-align: center;
  }
  
  .sectionTitle {
    color: #131313;
    font: 500 40px/1.4 Poppins, sans-serif;
    margin-bottom: 79px;
  }
  
  .stepsContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 75px;
  }
  
  .stepCard {
    background-color: #fff;
    border-radius: 25px;
    padding: 40px 20px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
  
  .stepIcon {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
  }
  
  .stepTitle {
    color: #141414;
    font: 600 24px/1.4 Poppins, sans-serif;
    margin-bottom: 20px;
  }
  
  .stepDescription {
    color: #727271;
    font: 400 20px/1.4 Poppins, sans-serif;
  }
  
  .bookAppointmentButton {
    background-color: #f5790c;
    color: #fff;
    border: 1px solid #f5790c;
    padding: 16px 49px;
    font: 400 18px/1.4 Poppins, sans-serif;
    cursor: pointer;
  }

  .stepCardIconContainer{
    display: flex;
    justify-content: center;
  }
  
  @media (max-width: 991px) {
    .bookingSteps {
      padding: 40px 20px;
    }
    .sectionTitle {
      font-size: 32px;
      margin-bottom: 40px;
    }
  
    .stepsContainer {
      flex-direction: column;
    }
  
    .bookAppointmentButton {
      padding: 12px 30px;
    }
  }