.hero {
  width: 100%;
  padding-top: 68px;
}

.heroImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.our-blogs-title-home {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.ourblogname {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

@media (max-width: 844px) {
  .ourblogname {
    font-size: 20px;
    font-weight: 700;
    margin-right: 20px;
    margin-top: -50px;
  }

  .homeblogrow {
    margin-top: -50px;
  }
}

@media (max-width: 390px) {
  .ourblogname {
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
    margin-top: -40px;
  }

  .homeblogrow {
    margin-top: -40px;
  }
}
