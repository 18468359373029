.Paid{
    text-align: center;
    padding: 2.5px;
    border-radius: 10px;
    background: green;
    color: white;
}

.Unpaid{
    text-align: center;
    padding: 2.5px;
    border-radius: 10px;
    background: red;
    color: white;
}


.table-container::-webkit-scrollbar {
    width: 6px; 
  }
  

  .table-container::-webkit-scrollbar-track {
    background: #007bff; 
  }

  .table-container::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 3px;
  }