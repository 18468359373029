.no-scroll {
    overflow: hidden;
  }

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1000;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #1FBFC20D;
}

.header p {
    font-size: 18px;
    font-weight: 500;
    color: #121212;
}

.popupContainer {
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    overflow: hidden;
    height: auto;
    /* max-height: 90%;
    overflow-y: auto; */
    font-family: Poppins;
    width: 40%;
  }

.mainContainer {
    background-color: white;
    padding: 25px;
    max-height: 90vh; 
    overflow-y: auto; 
    font-family: Poppins;
}

.mainContainer p {
    color: #727271;
    font-size: 18px;
    font-weight: 400;
}

.mainContainer h1 {
    color: #121212;
    font-size: 20px;
    font-weight: 500;
}


.typeContainer {
    height: 140px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #1FBFC2;
    color: #FFFFFF;
    cursor: pointer;
    margin: 10px 0px;
}

.addButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #A6ACB8;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .addButtonDiv p{
    margin: 0px;
    font-size: 70px;
    font-weight: 100;
    color: #A6ACB8;
  }

  .navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .progressBar1 {
    width: 100%;
    height: 5px;
    background-color: #D9D9D9;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
  }

  .ncdiv {
    padding: 25px;
    margin-bottom: 15px;
  }

  .ncdiv h1 {
    font-size: 20px;
    font-weight: 500;
  }

  .ncdiv p {
    font-size: 18px;
    font-weight: 400;
  }

  .ncdiv button {
    width: 143px;
    height: 40px;
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
  }

  .cameraDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(217, 217, 217, 0.75);
    background: rgba(243, 243, 243, 0.50);    
    height: 326px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 85px;
  }

  .submitButton {
    width: 140px;
  height: 40px;
  background: #F5790C;
  color: #FFFFFF;
  margin-bottom: 85px;
  }
  .imagePreviewContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .imagePreview {
    position: relative;
    border: 2px dashed #A6ACB8;
    margin-right: 10px;
    margin-bottom: 42px;
    padding: 5px;
  }
  .cancelButton {
    position: absolute;
    top: 1px;
    right: 7px;
    background: transparent;
    border: none;
    font-size: 14px;
    color: #A6ACBB;
    cursor: pointer;
  }
  .addButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #A6ACB8;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .addButtonDiv p{
    margin: 0px;
    font-size: 70px;
    font-weight: 100;
    color: #A6ACB8;
  }



  .videoPreviewContainer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .videoPreview {
    position: relative;
    border: 2px dashed #A6ACB8;
    margin-right: 10px;
    margin-bottom: 42px;
    padding: 5px;
  }
  
  .cancelButton {
    position: absolute;
    top: 1px;
    right: 7px;
    background: transparent;
    border: none;
    font-size: 14px;
    color: #A6ACBB;
    cursor: pointer;
  }
  
  
  