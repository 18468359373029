.aboutus-contaner-fluid {
  margin-top: 80px;
  background-color: #f6f9fe;
}
.imageTextContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fe1b3a4e44b4743868d52cf0d59eb257e"); /* Replace with the actual image path */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-text {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 700;
  line-height: 67.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.ourFounder-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

.card-button-socialmedia {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.ourFounder-sub-dec {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.founder-by-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  float: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.founder-by-text strong {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  float: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* General styles */

.image {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
}

/* Section layout */
.founder-section,
.story-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto;
  padding: 1rem;
  gap: 1rem;
  max-width: 1200px;
}

.images-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-top: -350px;
}

.image {
  /* width: 150px; 
    height: 150px; */
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
}

.founder-image {
  z-index: 1;
  top: 0;
  left: 120px;
}

.founder-image-rahul {
  width: 100%;
  height: 300px;
  left: 100px;
}

.dog-image {
  width: 200px;
  height: 200px;
  z-index: 2;
  left: 0;
  top: 150px;
  border: 5px solid #ffffff;
  border-radius: 30px;
}

.founder-text-container {
  flex: 1 1 300px;
  /* max-width: 500px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 768px) {
  .founder-section,
  .story-section {
    flex-direction: column;
    align-items: center;
  }

  .images-container {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .image {
    max-width: 100%;
  }
}

.Reach-us-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container-horizontal {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #00000012;
  width: 425px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;

  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 40px;
  margin-bottom: 10px;
  margin: 0 auto;
}

.card-title {
  margin-bottom: 8px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.20000000298023224px;
  text-underline-position: from-font;
  color: #131313;
  text-decoration-skip-ink: none;
}

.card-description {
  color: #787878;

  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-container-horizontal {
    flex-wrap: wrap; /* Allows wrapping for very small screens */
  }

  .card {
    flex: 1 1 100%;
  }
}

.contact-form-container {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.contact-form span {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.form-group {
  margin-bottom: 0px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #e4e4e4;
  outline: none;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  color: #fff;
  background-color: #f5790c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.GetinTouch {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

@media (max-width: 768px) {
  .contact-form-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .submit-btn {
    font-size: 14px;
    padding: 12px;
  }
}
