/* OptionsMenu.module.css */
.optionsMenu {
    position: absolute;
    top: 0; /* Aligns it with the MenuIcon */
    left: 30px; /* Adjust as needed to position it on the right */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 15px;
    z-index: 100;
  }
  
  .optionsMenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .optionsMenu li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid #F1F1F1;
    display: flex;
    width: 200px;
    padding-bottom: 0px;
  }

  .optionsMenu p {
    color: #121212;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 5px;
  }


  
  .optionsMenu li:hover {
    background-color: #f5f5f5;
  }
  