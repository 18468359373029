.petdaiarybackgroundcontainer {
  background-image: url("https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F6c285d8b32c44045b0f2213925b523a0"); /* Add your image URL here */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
  position: relative;
}

.petdaiarycontent {
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 25px;
}

.petdaiaryheading {
  font-family: Poppins;
  font-size: 96px;
  font-weight: 600;
  line-height: 134.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.petdaiarysubheading {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.petdaiaryctabutton {
  width: 174px;
  height: 50px;
  margin: 0 auto;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: 1px solid #fff;
  color: #ffffff;
}

.petdaiaryimgegridcontainer {
  display: flex;
  justify-content: center;
  gap: 35px;
  flex-wrap: wrap;
  padding: 20px;
  text-align: center;
}

.petdaiaryimageitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  max-width: 250px;
}

.petdaiaryimageitem img {
  width: 100%;
  height: auto;
}

.petdaiaryimagename {
  margin-top: 10px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

@media (max-width: 768px) {
  .petdaiaryimageitem {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .petdaiaryimageitem {
    width: 100%;
  }
}
/* ---------------------- */

.hero {
  position: relative;
  height: 100vh;
  /* min-height: 600px; */
  width: 100%;
  color: #fff;
  margin-top: 80px;
}

.heroBackground {
  position: absolute;
  inset: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  filter: brightness(0.5);
}
  
.heroDiv {
  position: relative;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.heroDiv h1 {
  font-size: 80px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 40px;
}

.heroDiv p {
  font-size: 30px;
  font-weight: 400;
  font-family: Poppins;
  margin-bottom: 60px;
}

.heroDiv button {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  height: 40px;
  width: 150px;
  border: 1px solid #FFFFFF;
}

.PetMenuDiv {
margin: 20px;
display: flex;
justify-content: center;
align-items: center;
height: 50vh;
}

.petMenuDiv2 {
width: 60%;
display: flex;
justify-content: space-between;
}

.petImage {
width: 100%;
height: auto;
max-height: 170px;
max-width: 170px;
border: 3px solid #F6F6F6;
border-radius: 20px;
margin-bottom: 20px;
}

.petMenuButton {
color: #131313;
font-size: 20px;
font-weight: 600;
}


.connectMenu {
width: 100%;
background-color: #1FBFC2;
border-radius: 25px;
text-align: center;
color: #FFFFFF;
margin-left: auto;
margin-right: auto;
padding-top: 60px;
padding-bottom: 60px;
font-family: Poppins;
}

.GrconnectMenu2 {
width: 80%;
margin-left: auto;
margin-right: auto;
}

.connectMenu2 h1 {
font-size: 26px;
font-size: 600;
margin-bottom: 50px;
}

.connectMenu2 p {
font-size: 14px;
font-weight: 400;
margin-bottom: 20px;
}

.connectMenu2 button {
font-size: 12px;
font-family: Poppins;
font-weight: 400;
height: 40px;
width: 150px;
border: 1px solid #FFFFFF;
margin-top: 30px;
}

.info {
width: 75%;
text-align: center;
margin-left: auto;
margin-right: auto;
padding-top: 60px;
padding-bottom: 60px;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}

.infoItem {
width: 30%;
background-color: #F6F9FE;
border-radius: 25px;
padding: 30px;
height: 220px;
margin-bottom: 35px;
font-family: Poppins;
}

.infoItem h1 {
font-size: 18px;
font-weight: 600;
margin-bottom: 20px;
}

.infoItem p {
font-size: 14px;
font-weight: 400;
margin-bottom: 20px;
color: #727271;
}

.hero2 {
position: relative;
height: 40vh;
min-height: 600px;
width: 100%;
color: #fff;
/* padding-top: 80px; */
}

.heroBackground2 {
position: absolute;
inset: 0;
height: 100%;
width: 100%;
object-fit: cover;
filter: brightness(0.3);
}

.heroDiv2 {
position: relative;
z-index: 1;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
width: 60%;
}

.heroDiv2 h1 {
font-size: 32px;
font-weight: 500;
font-family: Poppins;
margin-bottom: 40px;
}

.heroDiv2 p {
font-size: 16px;
font-weight: 400;
font-family: Poppins;
margin-bottom: 60px;
}

.faq {
background-color: #F6F6F6;
padding-top: 100px;
text-align: center;
padding-bottom: 80px;
}

.faq h1 {
font-family: poppins;
font-size: 32px;
font-weight: 500;
margin-bottom: 60px;
}

.faqDiv {
width: 75%;
margin-left: auto;
margin-right: auto;
padding: 40px;
background-color: #FFFFFF;
border-radius: 10px;
margin-bottom: 25px;
text-align: left;
cursor: pointer;
font-family: Poppins;
}

.faqDivQuestion {
font-size: 16px;
font-weight: 600;
color: #141414;
font-family: Poppins;
margin-bottom: 0px;

}

.answerTitle {
color: #727271;
font-size: 12px;
font-weight: 700;
margin-bottom: 0px;
}

.answerText {
color: #727271;
font-size: 12px;
font-weight: 400;
margin-bottom: 10px;
}

.answersDiv {
margin-top: 30px;
}

@media (max-width: 480px) {

.connectMenu {
  width: 90%;
}

.infoItem {
  width: 100%;
}

.hero {
  height: 30vh;
}

.heroDiv h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.heroDiv p {
  font-size: 14px;
  margin-bottom: 10px;
}

.heroDiv button {
  font-size: 8px;
  font-family: Poppins;
  font-weight: 400;
  height: 20px;
  width: 80px;
  border: 1px solid #FFFFFF;
}

.PetMenuDiv {
  margin: 10px;
  height: 30vh;
}

.petMenuDiv2 {
  width: 90%;
}

.petImage {
  width: 100%;
  max-height: 70px;
  max-width: 70px;
  margin-bottom: 10px;
}

.petMenuButton {
  font-size: 10px;
}

.hero2 {
  height: 30vh;
}

.faq h1 {
  font-size: 25px;
}

}

/* Tinder HomePage  */

.TinderHomePage {
width: 100%;
margin-top: 100px;
background-color: #F6F9FE;
padding-bottom: 80px;
position: relative;
height: 100vh;
overflow-y: auto;
}

.profileCardDiv {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 75%;
margin-left: auto;
margin-right: auto;
padding-top: 80px;
}

.petProfileCard {
width: 30%;
background-color: white;
border-radius: 5px;
margin-bottom: 20px;
}

.petProfileCard img{
width: 100%;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}

.petProfileCard2 {
padding: 25px;
padding-top: 30px;
font-family: Poppins;;
}

.petProfileCard2 h1{
font-weight: 600;
font-size: 20px;
color: #727271;
}

.petProfileCard2 p{
font-weight: 400;
font-size: 10px;
color: #727271;
margin-bottom: 10px;
}

.petProfileCard2 button{
font-size: 12px;
font-weight: 400;
height: 35px;
width: 100%;
color: #FFFFFF;
background-color: #F5790C;
}

.progressBar1 {
width: 100%;
height: 5px;
background-color: #D9D9D9;
border-radius: 5px;
margin-bottom: 10px;
position: relative;
}

.StatusPill {
width: 60px;
background-color: #1FBFC21A;
overflow: visible;
position: absolute;
top: -15px;
left: 35%;
text-align: center;
padding: 2px 7px;
cursor: pointer;
}

.StatusPill p {
color: #1FBFC2;
font-family: Poppins;
font-size: 6px;
font-weight: 400;
margin: 0px;
}

.addCard {
width: 30%;
background-color: white;
border-radius: 5px;
padding: 40px;
display: flex;
flex-direction: column;
align-items: center;
height: 200px;
color: #A6ACB8;
}

.addButtonDiv {
display: flex;
justify-content: center;
align-items: center;
border: 2px dashed #A6ACB8;
height: 100px;
width: 100px;
border-radius: 50%;
cursor: pointer;
margin-bottom: 10px;
}

.addButtonDiv p{
margin: 0px;
font-size: 70px;
font-weight: 100;
color: #A6ACB8;
margin-bottom: 15px;
margin-bottom: 20px;
}

.warningDiv {
width: 75%;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
/* margin-bottom: 80px; */
color: #BF0C0C;
font-family: Poppins;
background-color: #FFFFFF;
padding: 50px;
}

.warningDiv h1{
font-size: 15px;
font-weight: 600;
}

.warningDiv p{
font-size: 15px;
font-weight: 400;
}

@media (max-width: 480px) {
.petProfileCard {
  width: 100%;
}

.addCard {
  width: 100%;
}
}


/* Tinder Matches */

.matchesHeaderDiv {
padding: 25px 100px;
background-color: #1FBFC2;
color: #FFFFFF;
font-family: Poppins;
}

.pillsDiv {
border-radius: 25px;
background: rgba(255, 255, 255, 0.25);
padding: 10px 25px;
}

.pillsDiv p{
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
margin: 0px;
}

.chatButton {
/* display: flex;
justify-content: center;
align-items: center;
height: 75px;
width: 75px;
border-radius: 50%;
background-color: #F5790C; */
cursor: pointer;
position: fixed;
bottom: 10%;
right: 6%;
}

.profileCardDiv1 {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 90%;
margin-left: auto;
margin-right: auto;
padding-top: 80px;
}

.TinderHomePage1 {
width: 100%;
background-color: #F6F9FE;
padding-bottom: 80px;
position: relative;
/* overflow-y: auto; */
}

.locationInput {
height: 1px;
font-size: 12px;
}

.GrMidSectionContainer{
display:flex;
width:90vw;
margin-inline:auto;
justify-content:space-between;
padding-bottom: 60px;
}
.PetPicMainContainer{
display:flex;
width:40%;
justify-content:space-between;
position: relative;
}
.PetPicMainContainer img{
  width:100%;
  object-fit:contain;
}
/* .CatImgContainer{
} */
.CatImg{
position:absolute;
top:150px;
left:40px;

}
.DogImgContainer{
display: flex;
justify-content:flex-end;
}
.DogImg{
width:360px;
height:360px;
}
.GrWorkContainer{
display:flex;
width:50%;
flex-direction:column;
}
.WorkText{
font-size:20px;
color:#727272;
font-weight: 400;
margin-top:10px;
}
.WorkDetails{
display:flex;
flex-direction:row;
width: 100%;
justify-content:space-between;
padding:30px;
}
.GrMoreDetails{
width:100%;
display:flex;
flex-direction: column;
background-color:#f6f9fe;
padding:100px;
}
.GrZaanvarText{
margin-inline: auto;
}
.GrMainCardscontainer{
width:90%;
display:flex;
justify-content:space-evenly;
margin-inline:auto;
margin-top:60px;
}
.GrCardsContainer{
width:20%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
background-color:white;
padding:20px;
cursor:pointer;
}
.GrCustomerDetailsMainContainer{
width:100%;
display:flex;
flex-direction: column;
padding:100px;
}
.GrCustomerContainer{
width:90%;
display:flex;
justify-content:center;
margin-inline:auto;
margin-top:60px;
}
.GrAboutCustomers{
display:flex;
flex-direction: column;
justify-content:center;
align-items:center;
box-shadow: 0px 0px 10px 0px #0000001A;
padding:20px;

}
.CommunityContainer{
display:flex;
width:100%;
justify-content:space-between;
gap:10px;
margin-top:40px;
}
.CommunityDetails{
display:flex;
width:20%;
justify-content: space-between;
border:1px solid white;
border-radius:5px;
padding:14px;
}
.GrCountContainer{
display:flex;
flex-direction:column;
}