.no-scroll {
    overflow: hidden;
  }

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContainer {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  overflow: hidden;
  height: auto;
  max-height: 90%;
  font-family: Poppins;
  width: 70%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #4D4D4D;
}

.header p {
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.mainContainer {
    background-color: white;
    /* min-height: 100vh; */
    padding: 60px;
    max-height: 90vh; /* Constrain height to make room for scroll */
    overflow-y: auto; /* Enable scrolling when overflowed */
}

.mainContainer p {
    font-size: 16px;
    font-weight: 600;
}

.progressBarDiv {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 10px;
}

.progressBar {
    width: 10%;
    height: 5px;
    border-radius: 5px;
    background-color: #A6ACB8;
}

.step1Container {
    padding: 35px 0px;
    overflow-y: auto;
}

.step1Container p {
    font-size: 14px;
    font-weight: 500;
}

.typeContainer {
    height: 140px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #1FBFC2;
    color: #FFFFFF;
    cursor: pointer;
    margin: 10px 0px;
}

.addButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #A6ACB8;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .addButtonDiv p{
    margin: 0px;
    font-size: 70px;
    font-weight: 100;
    color: #A6ACB8;
  }

  .navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .step1Container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  .addButtonDiv {
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imagePreviewContainer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .imagePreview {
    position: relative;
    border: 2px dashed #A6ACB8;
    margin-right: 10px;
    margin-bottom: 42px;
    padding: 5px;
  }
  
  .cancelButton {
    position: absolute;
    top: 1px;
    right: 7px;
    background: transparent;
    border: none;
    font-size: 14px;
    color: #A6ACBB;
    cursor: pointer;
  }
  