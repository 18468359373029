.LostAndFoundHomePage {
    width: 100%;
    background-color: #FFFFFF;
    margin-top: 100px;
    font-family: Poppins;
  }
  
  .sortDiv {
      display: flex;
      height: 75px;
      align-items: center;
      padding: 23px 50px;
      border-bottom: 1px solid #F0F1F6;
      border-left: 1px solid #F0F1F6;
      border-top: 1px solid #F0F1F6;
  
  
  }
  
  .sortDiv h1 {
      color: #61677F;
      font-size: 16px;
      font-weight: 500;
  }
  
  /* .filtersDiv {
       padding: 50px; 
  
  } */
  
  .filtersDiv {
      /* width: 30%; */
      width: 280px;
  }
  
  .cardlistDiv {
      width: 83%;
      background: #F6F9FE;
    
  }
  .lostfoundsearch{
      font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4A4848;
  margin-left: 25px;
  margin-bottom: 30px;
  margin-top: 33px;
  
  }
  .clearButton{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #61677F;
  
  }
  .lostAndFoundNewPost {
      position: absolute;
      top: 200px; 
      right: 48px; 
      width: 83px;
      height: 40px;
      background: #F5790C;
      text-align: center;
      color: white;
      padding: 10px 0px 0px 0px;
      font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 18.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .lostAndFoundMsg {
      position: fixed;
      bottom:  90px;
      right: 20px; 
     
      
  cursor: pointer;
  
    }
    /*-------------------------------lost and found pet details---------------------------*/
    .petdetailspopupoverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    .petDetailsPopupcontent {
      background: white;
      width: 572px;
      height: auto;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow-y: auto; 
    }
    
    .petDetailsPopupClose {
      position: absolute;
      top: 20px;
      right: 15px;
      background: #00000080;
      border: none;
      font-size: 20px;
      cursor: pointer;
      padding: 10px;
      border-radius: 50px;
      
    }
    
    .petDetaildsPopupHearder {
      width: 100%;
      height: auto;
      margin-bottom: 20px; 
      background: #1FBFC20D;
    }
    
    .petDetailsPopupHeader img {
      width: 100%;
      height: auto;
    }
    
    .petDetailsPopupContentText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* gap: 10px; */
    }
    
    .petDetailsPopupContentText h2 {
      font-size: 1.5rem;
      margin: 0;
    }
    
    .petDetailsPopupContentText  {
      margin: 5px 0;
      font-size: 1rem;
    }
    .petDetailsPetName{
      font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 0px 5px 10px 50px;
  
    }
    .petDetailspetId{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 8.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 10px 5px 10px 50px;
    }
    .petDetailsgender{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 8.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 10px 5px 10px 10px;
    }
    .petDetailsAerafound{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 10px 5px 18px 50px;
  
    }
    .petDetailsunderLine{
      width: 92%;
    height: 2px; 
    background-color:  #F1F1F1; 
    display: flex;
    margin-left: 20px;
  
    }
    .petDetailsDetails{
      width: 80%;
      height: auto;
      margin: 18px 20px 18px 50px;
      background-color: #F8F8F8;
    }
    .petDetailsDetailstext{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 20px;
    }
   .petOwnerDetails{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
  padding: 10px 5px 0px 50px;
  
  
   }
   .petOwnerprofile{
  
      padding: 10px 5px 0px 50px;
      
  
   }
   .petDetailProfileName{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
  padding: 5px;
  margin-bottom: 0px;
   }
   .petDetailProfileloction{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 5px;
  margin-bottom: 0px;
  
   }
   .petDetailProfileMobile{
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  padding: 5px;
  margin-bottom: 0px;
  
   }
   .petDetailbutton{
      width: 100%;
      background-color: #F5790C;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      margin-bottom: -7px;
   }
   .petDetailflexContainer {
      display: flex;
      justify-content: space-between;
      width: 100%; 
  }
  .petDetailFoundDate {
      font-family: Poppins;
      font-size: 12px !important;
      font-weight: 400;
      line-height: 19.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #727271;
      
      padding: 10px 0px 10px 50px;
      margin-bottom: 0px;
  }
  
  .petDetailTime {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: right;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #727271;
      
      padding: 10px 70px 10px 0px;
      margin-bottom: 0px;
  }
  
  /*-------------------------------------------Chat Page-------------------------------------------*/
  .lostAndFoundChats {
      background: white;
      /* padding: 20px; */
      width: 450px;
      text-align: center;
      position: relative;
      width: 572px;
  height: 476px;
  gap: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  
    }
    .lostAndFondChatclose {
      position: absolute;
      top: 20px;
      right: 25px;
      background: transparent;
      border: none;
      font-size: 30px;
      cursor: pointer;
      color: #121212;
     
    }
    .LostAndFoundChathearder{
      width: 572px;
  height: 60px;
  gap: 0px;
  background: #1FBFC20D;
  margin: 0px;
  box-shadow: 0px 0px 10px 0px #0000001A;
  
  }
    .LostAndFoundChat{
      width: 45px;
  height: 25px;
  top: 18px;
  left: 25px;
  gap: 0px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 15px 0px 0px 30px;
  
    }
    
    .lostAndFondChatProfileDiv {
      border-bottom: 1px solid #f1f1f1;
      padding: 10px;
      margin: 0 20px;
    }
    
    .lostAndFondChatProfileDiv img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
    
    .lostAndFondChatProfileDiv p {
      margin: 0;
    }
    
    .lostAndFondChatProfileDiv .time {
      font-size: 12px;
      color: gray;
    }
    .lostAndFondChatname{
      font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .lostAndFondChatlastchat{
      font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .LostAndFoundChatTime{
      font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .LostAndFoundChatdiv2{
      margin: 15px;
    }
    .crossButton {
  
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.50);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;;
    }
  
    /*----------------------------------Single Chat page--------------------------------------------*/
    .LostAndFoundSingleChathearder {
      width: 552px;
      height: 80px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #66666626;
      padding: 0 15px; 
      margin: 10px;
    }
    
    .LostAndFoundSingleChat {
      display: flex;
      align-items: center; 
      justify-content: space-between; 
      width: 100%;
    }
    
    .chatimg {
      width: 35.26px;
      height: 15px;
      padding-right: 10px;
    }
    
   
    
    .LostAndFondsingleChatclose {
      position: absolute;
      top: 35px;
      left: 25px;
      background: transparent;
      border: none;
      font-size: 30px;
      cursor: pointer;
      
    }
    .SingleChatName{
      font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .SingleChatOnline{
      font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .SingleChatChatBody{
      margin: 20px;
    }
    .SingleChatChatbodyDIv{
      width: auto;
      height: auto;
      gap: 0px;
      border-radius: 5px;
  background-color: #7272711A;    
  margin: 0px 10px 10px 10px;
  padding: 5px 8px 5px 15px;
  text-align: left;
    }
    .SingleChatChatmsgs{
      font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
    }
    .SingleChatPageinputsend{
      padding: 10px;
      background-color: #F1F1F1;
      border-radius: 50px;
      width: 100%;
  height: 48px;
  top: 407px;
  left: 25px;
  gap: 0px;
  display: flex;
  
    }
    .SingleChatChatinput {
      border: 1px solid #F1F1F1; 
      background-color: #F1F1F1;
      padding: 5px; 
      outline: none; 
      border-radius: 4px; 
      transition: border-color 0.3s; 
  }
  
  .SingleChatChatinput:focus {
      border-color: 1px solid#F1F1F1; 
      background-color: #F1F1F1; 
  }
  
  .SingleChatChatinput::placeholder {
      color:#F1F1F1;
  }
  .SingleChatChatmenuDropdown {
      position: absolute;
      top: 40px;
      right: 0;
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      z-index: 10;
      width: 120px;
    }
    
    .SingleChatChatmenuDropdown ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    
    .SingleChatChatmenuDropdown ul li {
      padding: 10px 15px;
      cursor: pointer;
      font-size: 14px;
    }
    
    .SingleChatChatmenuDropdown li:hover {
      background-color: #f5f5f5;
    }
    
    
  
    .inputElement {
      width: 410px;
      height: 48px;
      border-radius: 25px;
      background: #F1F1F1;
      position: fixed;
    top: 50%;
      display: flex;
      padding-right: 10px;
      align-items: center;
  }
  
  .inputElement input{
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
  }
  