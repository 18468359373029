.socialMedia-flex-container {
  display: flex;
  width: 80%;

  /* margin: auto; */
}

.left-box {
  /* width: 800px; */
  flex: 1;
  padding: 1rem;
}

.right-box {
  flex: 1;
  padding: 1rem;
  /* width: 400px; */
  /* width: 300px; */
}

.socialmedia-box {
  background-color: white;
  margin-bottom: 15px;
  width: 690px;
  padding: 15px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
}

.socialmedia-rightbox {
  background-color: white;
  padding: 15px;
  width: 380px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
}

.socialmediaheader {
  display: flex;
  justify-content: space-between;
}

.socialmedia-profile {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.socialmedia-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid black;
  object-fit: cover;
}

.socialmedia-profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.socialmedia-profile-name {
  font-size: 1.2rem;
  margin: 0;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-profile-date {
  font-size: 0.9rem;
  color: gray;
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-follow-btn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border: 1px solid #b1aba0;
  width: 100px;
  height: 40px;
  gap: 0px;
}

.socialmedia-dec span {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 20px;
}

.socialmedia-img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.socialmedia-bottom-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.socialmedia-left-icons {
  display: flex;
  gap: 20px;
}

.icon-with-text {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #555;
}

.icon-with-text p {
  margin-top: 12px;
  font-size: 14px;
  color: #555;
}

.icon-with-text i {
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s;
}

.icon-with-text:hover i {
  color: #007bff;
}

.socialmedia-search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.socialmedia-search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.search-input {
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 14px;
  outline: none;
}

.search-input:focus {
  border-color: #e0e0e0;
}

.search-input::placeholder {
  padding-left: 10px;
  color: #888;
}

.search-icon {
  position: absolute;
  right: 10px;
  font-size: 18px;
  margin-top: -25px;
  pointer-events: none;
}

.socialmedia-right-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 5px;
  margin-left: 13px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-suggested-list {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  padding: 10px;
}

.socialmedia-follow-list {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  padding-bottom: 15px;
  gap: 10px;
}

.suggestion-horizental-display {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.socialmedia-follow-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid black;
  object-fit: cover;
}

.socialmedia-suggested-list-name {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.suggested-list-primary {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.suggested-list-secondary {
  font-size: 10px;
  color: gray;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.suggested-follow-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1fbfc2;
  cursor: pointer;
}

.suggested-follow-title:hover {
  text-decoration: underline;
}

.socialmedia-see-all {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.socialmedia-left-side {
  width: 20%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 84.6vh;
  overflow-y: auto;
}

.socialmedia-right-side {
  width: 80%;
  box-sizing: border-box;
  background-color: #f6f9fe;
  height: 84.6vh;
  overflow-y: auto;

  flex-grow: 1;
  position: relative;
  z-index: 1;
}

.socialmedia-sidebar {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.socialmedia-sidebar-item {
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  /* margin-right: 80px; */
  gap: 20px;
  width: 250px;
}

.socialmedia-sidebar-icon {
  font-size: 20px;
  margin-right: 10px;
}

.socialmedia-search-input-sideBar {
  width: 200px;
  height: 40px;
  border: none;
  padding: 5px;
  border-radius: 10px;
  outline: none;
}

.socialmedia-search-input-sideBar:focus {
  border: 0.5px solid #727271;
  /* box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); */
}

/* .socialmedia-sidebar-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
} */

.socialmedia-sidebar-item:hover {
  background-color: #f0f1f6;
  border-radius: 15px;
}

.socialmedia-sidebar-item.active {
  background-color: #f0f1f6;
  color: #1fbfc2;
  border-radius: 15px;
}

.socialmedia-sidebar-item.active .socialmedia-sidebar-icon {
  color: #1fbfc2;
}

.socialmedia-create-dropdown label {
  /* display: block; */
  margin-bottom: 10px;
  margin-left: 10px;
}

.socialmedia-create-dropdown input {
  margin-right: 8px;
}

/* Modal wrapper */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  /* Hidden by default */
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

/* Show modal when it has the active class */
.custom-modal.active {
  display: flex;
}

/* Modal dialog */
.custom-modal-dialog {
  background: white;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Modal content */
.custom-modal-content {
  display: flex;
  flex-direction: column;
}

/* Header */
.custom-modal-header {
  background-color: #1fbfc20d;
  color: rgb(0, 0, 0);
  padding: 16px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-forms-display {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.modal-header-imageModal {
  display: flex;
  justify-content: space-between;
  height: 56px;
  background-color: #ddf5f6;
  padding: 10px;
  align-items: center;
}

/* Close button */
.custom-modal-close {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

/* Body */
.custom-modal-body {
  padding: 16px;
}

/* Footer */
.custom-modal-footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  background: #f1f1f1;
}

/* Button */
.custom-modal-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-modal-button:hover {
  background: #0056b3;
}

.image-container.fit img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.image-container.unfit img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-gallery {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

.arrow-btn {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: #333;
  cursor: pointer;
}

.notyfy-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notyfy-icon {
  width: 24px;
  height: 24px;
}

/* .notyfy-text {
  margin-top: -70px;
} */

.notify-left-data {
  display: flex;
  align-items: center;
  justify-content: start;
}

.notyfy-close-icon:hover {
  color: #000;
}

.nofify-Img {
  position: relative;
  width: 100px;
  height: 100px;
}

.notyfy-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border: 1px solid black;
  border-radius: 50%;
}

.notyfy-icon:nth-child(2) {
  z-index: 2;
  left: 30px;
  top: 0px;
}

.notyfy-icon:nth-child(3) {
  z-index: 3;
  left: 50px;
  top: 0px;
}

.notify-icon-down {
  margin-top: -70px;
}
.notify-col-text {
  display: flex;
  flex-direction: column;
  margin-top: -60px;
}

.notyfy-text-main {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.notyfy-sub-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.notify-likes-header {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 15px;
}

.notyfy-like-image {
  width: 40px;
  height: 40px;
  z-index: 1;
  border: 1px solid black;
  border-radius: 50%;
}

.notify-like-header {
  display: flex;
  flex-direction: column;
}

.notify-like-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 15px;
}

.notify-like-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.notify-like-sub-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.notify-month-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.notyfy-month-img {
  width: 40px;
  height: 40px;
  z-index: 1;
  border: 1px solid black;
  border-radius: 50%;
}

.notify-month-left {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.notify-month-left-text {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.notify-month-text-main {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.notify-month-text-sub {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  margin-top: -15px;
}

.notify-month-confirm {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
}

.notify-month-following {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1fbfc2;
}
.notify-month-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Earlier */

.notify-earlier-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.notyfy-earlier-img {
  width: 40px;
  height: 40px;
  z-index: 1;
  border: 1px solid black;
  border-radius: 50%;
}

.notify-earlier-left {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.notify-earlier-left-text {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.notify-earlier-text-main {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.notify-earlier-text-sub {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  margin-top: -15px;
}

.notify-earlier-confirm {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
}

.notify-earlier-following {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1fbfc2;
}

.notify-earlier-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.notify-followrRequest {
  display: flex;
  justify-content: flex-start;
  margin-top: 18px;
  gap: 10px;
  margin-left: 15px;
}

.notify-back-tick {
  margin: 5px 0;
}

.notify-followrRequest-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.notify-followrRequest-header {
  background-color: #1fbfc20d;
  height: 60px;
}

.image-previews {
  margin-top: 10px;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-thumbnail img {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background-color: #f8f9fa;
}

.form-label-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.imageNext_Save {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  margin-top: 15px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-container.show {
  opacity: 1;
  visibility: visible;
}

@keyframes fadeIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.socialmadia-profile {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  width: 97%;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 15px;
}

.socialmedia-profile-left-side {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: 55px;
  width: 800px;
}

.socialmedia-profile-image-home {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #aaa;
}

.socialmedia-profile-image-home img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.socialmedia-profile-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.socialmedia-profile-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.socialmedia-profile-description {
  font-size: 14px;
  color: #555;
}

.socialmedia-profile-right-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  margin-right: 50px;
}

.top-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.socialmedia-button {
  padding: 10px 20px;
  color: #727271;
  border: 1px solid #727271;
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-button:hover {
  background-color: #f5790c;
}

.socialmedia-account-details {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a6acb8;
}

.socialmedia-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100px;
}

.socialmedia-menu-container div {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.socialmedia-account-item-tabs.active {
  border-bottom: 2px solid #f5790c;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  padding: 10px;
}

.grid-image {
  border-radius: 5px;
  object-fit: cover;
  transition: transform 0.2s ease;
  width: 400px;
  height: 400px;
}

.grid-image:hover {
  transform: scale(1.05);
}

.socialmedia-image-text {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.socialmedia-profile-image-QandA {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
  filter: brightness(1.2);
}

.socialmedia-user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.socialmedia-user-name {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.socialmedia-user-time {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  color: #727271;
  text-decoration-skip-ink: none;
}

.socialmedia-user-info-dec {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 25px;
  margin-left: 25px;
}

.socilamedia-QandA-header {
  background-color: white;
  padding: 25px 15px;
  margin-bottom: 55px;
  width: 97%;
  margin-left: 15px;
}

.no-data-message {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
  margin-top: 20px;
}

.socialmedia-bottom-icons-QandA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.socialmedia-comment-modal-overlay {
  display: none;
  position: fixed;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 1050; */
}

.socialmedia-comment-modal-overlay.active {
  display: block;
}

.socialmedia-comment-modal-content {
  position: relative;
  margin: auto;
  /* background-color: white; */
  max-width: 1050px;
  height: 50vh;
  z-index: 1060;
  border-radius: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
}

.socialmedia-comment-modal-body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.socialmedia-comment-modal-image {
  height: 369.5px;
  width: 500px;
  object-fit: cover;
}

.socialmedia-comment-modal-details {
  width: 50%;
}

.socialmedia-user-info {
  font-size: 16px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.socialmedia-comments-section {
  flex-grow: 1;
  overflow-y: auto;
  height: 220px;
  max-height: 220px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
  padding: 10px;
}

.socialmedia-comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.comment-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.comment-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.comment-content span {
  margin: 0;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-comment {
  margin-bottom: 5px;
}

.socialmedia-input-section {
  display: flex;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.socialmedia-comment-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: none;
  margin-right: 10px;
  background-color: #ffffff;
}

.socialmedia-comment-send-btn {
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.socialmedia-comment-input:focus {
  border-color: transparent !important;
  outline: none !important;
  background-color: white;
}

.socialmedia-comment-view-row {
  display: flex;
  justify-content: space-between;
}

.socialmedia-user-info-comment-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 5px;
}

.socialmedia-comment-user-details-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}

.socialmedia-comment-user-details-column-text {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.socialmedia-comment-user-details-column-date {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.socialmedia-comment-modal-image-container {
  position: relative;
  display: inline-block;
}

.socialmedia-comment-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.socialmedia-comment-follow-btn {
  width: 100px;
  height: 40px;
  border: 1px solid #727271;
  opacity: 0px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  margin-top: 15px;
  margin-right: 15px;
}

.socialmedia-profile-dropdown {
  position: absolute;
  top: 46%;
  left: 15;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.socialmedia-side-profile-dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  color: #333;
  text-align: left;
  transition: background-color 0.3s ease;
}

.socialmedia-side-profile-dropdown-item:hover {
  background-color: #f0f0f0;
}
.carousels-container {
  display: flex;
  flex-direction: row;
  gap: 0px;
}

.carousel-container {
  position: relative;
  max-width: 800px;
  margin: auto;
}

.carousel {
  position: relative;
  overflow: hidden;
}

.carousel-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  flex: 0 0 100%;
  display: none;
}

.carousel-image.active {
  display: block;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

.socialmedia-poll-image-text-row {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #fff;
  padding: 25px;
  width: 97%;
  margin-left: 15px;
}

.socialmedia-poll-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.socialmedia-poll-text {
  display: flex;
  flex-direction: column;
}

.socialmedia-poll-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
}

.socialmedia-poll-date {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
}

.carousels-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-images {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.carousel-image.active {
  opacity: 1;
  z-index: 1;
}

.carousel-image.inactive {
  opacity: 0;
  z-index: 0;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

/* .carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
} */

.icon-with-text {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
}

.heart-icon-container {
  position: relative;
  display: inline-block;
  font-size: 24px;
}

.heart-icon-container .fa-heart {
  transition: color 0.3s ease, transform 0.2s ease;
}

.heart-icon-container .fa-heart.active {
  transform: scale(1.2);
}

.heart-icon-container:hover .fa-heart {
  transform: scale(1.1);
}

.heart-animation {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
  /* background-color: rgba(255, 0, 0, 0.3); */
  border-radius: 50%;
  animation: pulse 0.5s ease-out;
  pointer-events: none;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.socialmedia-notification-header {
  background-color: #fff;
  width: 420px;
  padding: 15px;
  height: 87vh;
  overflow-y: auto;
  /* box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2); */

  box-shadow: 0px 0px 10px 0px #0000000d;

  border-radius: 0px 10px 10px 0px;
  /* opacity: 0px; */
}

.create-bottom-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background-color: white;
  padding: 10px;
}

.create-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.create-option input[type="radio"] {
  width: 16px !important;
  height: 16px !important;
}

.create-option label {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-top: -30px;
}

.socialmedia-ImagePollModal-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.socialmedia-ImagePollModal-header.open {
  opacity: 1;
}

.socialmedia-ImagePollModal-body {
  background-color: #fff;
  text-align: center;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.socialmedia-ImagePollModal-body.open {
  transform: scale(1);
}

.socialmedia-ImagePollModal-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #a6acb8;
}

.nav-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.socialmedia-ImagePollModal-image-form {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  height: 500px;
  overflow-y: auto;
}

.caption-input-container {
  display: flex;
  align-items: center;
}

.emoji-btn {
  background: transparent;
  border: none;
  font-size: 22px;
  cursor: pointer;
  margin-right: 10px;
}

.emoji-picker {
  position: absolute;
  z-index: 999;
}

.caption-char-count {
  margin-top: 5px;
  font-size: 12px;
  color: #8e8e8e;
  text-align: right;
}

.socialmedia-textPollModal-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.socialmedia-textPollModal-header.open {
  opacity: 1;
}

.socialmedia-textPollModal-body {
  background-color: #fff;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  width: 500px;

  transition: transform 0.3s ease-in-out;
}

.socialmedia-textPollModal-body.open {
  transform: scale(1);
}
.socialmedia-textPollModal-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: #ddf5f6;
}

.textPoll-padding {
  padding: 15px;
  height: 400px;
  overflow-y: auto;
}

.socialmedia-textPollModal-question {
  margin-right: 290px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-textPollModal-poll-option {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-right: 350px;
  color: #121212;
}

.socialmedia-textPollModal-button {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #121212;
  margin-right: 15px;
}

.socialmedia-textPollModal-add-Option {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f5790c;
  padding-bottom: 20px;
}
/* side bar css */

.socialmedia-main {
  margin-top: 73px;
  display: flex;
}

.socialmedia-left-side {
  width: 20%; /* Default width */
  background-color: #ffffff;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  height: 84.6vh;
  overflow-y: auto;
  transition: width 0.3s ease-in-out; /* Smooth transition for width */
}

.socialmedia-left-side.collapsed {
  width: 20%;
}
.socialmedia-sidebar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.socialmedia-sidebar-item {
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  /* justify-content: center; Center icons */
  transition: all 0.3s ease-in-out;
}

.socialmedia-sidebar-icon {
  font-size: 20px;
  margin-right: 0px;
}

.socialmedia-sidebar-text {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.socialmedia-left-side.collapsed .socialmedia-sidebar-text {
  opacity: 0;
  visibility: hidden;
}

.notification-panel {
  position: absolute;
  top: 12%;
  left: 5%;
  margin-left: 10px;
  width: 30%;
  height: 87vh;
  overflow-y: auto;
  /* background-color: #ffffff; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
  z-index: 10;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.notification-panel.open {
  transform: translateX(0);
}

.notification-badge {
  /* position: absolute; */

  background-color: #ff3b30;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  margin-left: 15px;
}

.hover-profile-container {
  position: relative;
  display: inline-block;
}

.socialmedia-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.hover-card {
  position: absolute;
  top: 60px;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.hover-profile-container {
  position: relative;
  display: inline-block; /* Keeps the profile image in place */
}

.socialmedia-profile-image {
  object-fit: cover;
  transition: transform 0.3s ease;
}

.socialmedia-hover-card {
  position: absolute;
  top: 0;
  left: 110%;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* max-width: 250px; */
  width: 410px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.hover-profile-container:hover .socialmedia-hover-card {
  opacity: 1;
  pointer-events: auto;
}

.hover-card-image {
  width: 200px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.hover-card-info {
  font-family: Arial, sans-serif;
}

.hover-card-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.hover-card-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.social-media-hover-posts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

/* Modal overlay */
.following-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.following-modal-container {
  background: #fff;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* padding: 20px; */
  position: relative;
}

/* Modal header */
.following-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: #1fbfc20d;
  height: 40px;
}

.following-modal-header h2 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 15px;
  margin-top: 5px;
}

.following-modal-close-button {
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  margin-right: 15px;
}

/* Modal content */
.following-modal-content {
  max-height: 350px;
  overflow-y: auto;
}

/* User list styles */
.following-user-list {
  list-style: none;
  padding: 0 15px 0 15px;
  margin: 0;
}

.following-user-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.following-user-item-img-name {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.following-user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
  filter: brightness(1.2);
}

.following-user-name {
  font-size: 1rem;
  color: #333;
}

/* No users message */
.following-no-users {
  text-align: center;
  color: #999;
}

/* share post modal */

.sharepost-classname-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.sharepost-classname-modal {
  background-color: white;
  /* padding: 20px; */
  border-radius: 8px;
  width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

.sharepost-classname-content {
  margin-top: 20px;
}

.sharepost-classname-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.sharepost-classname-button {
  background-color: #1fbfc2;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 70px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sharepost-classname-button:hover {
  background-color: #17a59f;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sharepost-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  height: 300px;
  padding: 15px;
  overflow-y: auto;
}

.sharepost-container::-webkit-scrollbar {
  width: 8px;
}

.sharepost-container::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}

.sharepost-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.sharepost-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 20px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .sharepost-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.sharepost-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* border: 1px solid #ddd; */
  /* padding: 10px; */
  /* border-radius: 8px; */
  background-color: #ffffff;
}

.sharepost-profile-picture {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.user-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.sharePost-input {
  width: 90%;
  padding: 10px 15px;
  font-size: 16px;
  font-family: Poppins;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  margin: 0 auto;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.sharePost-input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 5px rgba(195, 195, 196, 0.5);
}

.sharePost-input::placeholder {
  color: #aaa;
  font-style: italic;
}

.sharePost-header-names {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ddf5f6;
}

.sharePost-header-names p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  color: #121212;
}

.sharepost-classname-close {
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #121212;
  cursor: pointer;
}

.sharepost-item.selected {
  position: relative;
  z-index: 5;
}

.tick-mark {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-btn {
  background-color: transparent;
  border: 1px solid #e53935;
  color: #e53935;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}
.Following-btn {
  background-color: transparent;
  border: 1px solid #f7a117;
  color: #0f0f0f;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}
.instagram-caption-container {
}

.instagram-caption-form-label-text {
  color: #262626;
  margin-bottom: 8px;
  display: block;
  display: flex;
  align-items: self-start;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.caption-input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.caption-textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  font-size: 14px;
  color: #262626;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  resize: none;
}

.emoji-picker-toggle {
  margin-top: 8px;
  align-self: flex-start;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.emoji-picker-container {
  position: absolute;
  top: 90px;
  z-index: 1000;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 40px;
}

.caption-char-count {
  margin-top: -20px;
  font-size: 12px;
  color: #8e8e8e;
  text-align: right;
}

.following-user-action-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.following-user-action-button:hover {
  background-color: #0056b3;
}

.following-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Container for each image */
.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Style for the image */
.hover-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease;
  backface-visibility: hidden;
}

/* Overlay container to hold text */
.middle {
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Hover effect on the container */
.hover-container:hover .image {
  opacity: 0.3; /* Reduce opacity of the image */
}

/* Display the text when the container is hovered */
.hover-container:hover .middle {
  opacity: 1;
}

/* Text style */
.text {
  background-color: rgba(
    4,
    170,
    109,
    0.7
  ); /* Green background with some transparency */
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 5px;
}
