.servicecontainer {
    border-radius: 25px;
    background-color: rgba(246, 249, 254, 1);
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 34px 42px;
    position: relative;
  }
  .subServices{
    display: flex;
  }
 E-Reportem{
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
  }

 E-Reportem:hover {
    color: black;
  }

  .featureSelector {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-direction: column;
    border-right: 1px solid black;
    padding-right: 16px;
    cursor: pointer;
  }
  
  
  .mainService {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(144, 154, 163, 1);
    border-bottom: 3px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
  }
  .featureItem:hover{
    color: rgba(19, 19, 19, 0.7);
    cursor: pointer;
  }
  
   .selectedService {
    color: rgba(19, 19, 19, 1);
    /* border-bottom: 3px solid rgba(19, 19, 19, 1); */
  } 
  
  .mainService:hover {
    color: rgba(19, 19, 19, 0.7);
  }
  
  @media (max-width: 991px) {
    .servicecontainer {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  .content {
    display: flex;
    width: 100%;
    max-width: 1190px;
    align-items: center;
    gap: 72px;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .content {
      max-width: 100%;
    }
  }
  
  .featureColumn {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 20px;
    color: rgba(144, 154, 163, 1);
    font-weight: 500;
    margin: auto 0;
    gap: 42px;
  }
  
  .highlightedFeature {
    color: rgba(19, 19, 19, 1);
    margin-top: 25px;
  }
  
  .divider {
    align-self: stretch;
    width: 1px;
    height: 270px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 20px;
    margin-right: 20px;

    
  }
  
  .featureImage {
    aspect-ratio: 1.65;
    object-fit: contain;
    object-position: center;
    width: 32%;
    border-radius: 25px;
    align-self: stretch;
    position: absolute;
    bottom: 18px;
    left: 66%;
}
  
  
  @media (max-width: 991px) {
    .featureImage {
      max-width: 100%;
    }
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }