.footer {
  background-color: #fff;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 10px 0px #0000001a;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.footerLogo {
  width: 80px;
  height: auto;
  margin: 0 !important;
}

.footerNav {
  display: flex;
  gap: 20px;
}

.footerLink {
  color: #131313;
  font: 400 18px Poppins, sans-serif;
  text-decoration: none;
}

.socialLinks {
  display: flex;
  gap: 15px;
}

.socialIcon {
  width: 25px;
  height: 25px;
}

.footerDivider {
  border-top: 1px solid rgba(102, 102, 102, 0.15);
  margin: 49px 0 29px;
}

.footerCopyright {
  color: #666;
  font: 400 14px Poppins, sans-serif;
  text-align: center;
  cursor: pointer;
}
.footerCopyright a {
  color: #666;
  font: 400 14px Poppins, sans-serif;
  text-align: center;
  padding-left: 5px;
  text-decoration: none;
}

@media (max-width: 991px) {
  .footer {
    padding: 40px 20px;
  }

  .footerContent {
    flex-direction: column;
    gap: 20px;
  }

  .footerNav {
    flex-wrap: wrap;
    justify-content: center;
  }
}
