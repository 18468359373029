.petProfileCard img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#saveicon {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 14px;
}
#lost-and-found-sort {
  height: 261%;
  padding-top: 25px;
}
#lost-found-selecedoption {
  width: auto;
  height: 39px;
  top: 143px;
  left: 245px;
  gap: 0px;
  border-radius: 25px 25px 25px 25px;
  background: #e1f4f8;
  text-align: center;
  color: #61677f;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-top: 5px;

  display: flex;
  margin: 10px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 36px;
}

.petProfileCard2 {
  padding: 25px;
  padding-top: 30px;
  font-family: Poppins;
}

.petProfileCard2 h1 {
  font-weight: 600;
  font-size: 20px;
  color: #727271;
}

.petProfileCard2 p {
  font-weight: 400;
  font-size: 10px;
  color: #727271;
  margin-bottom: 10px;
}

.petProfileCard2 button {
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  width: 100%;
  color: #ffffff;
  background-color: #f5790c;
}

.petProfileCard1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px;
    width: 100%; 
    margin: 0 auto; 
    padding: 10px 40px;
    
}

.petProfileCard2 {
  width: 370px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 0px;
  height: 414px;
  /* height: 400px; */
  /* margin: 20px; */

  /* margin-left: 25px;
  font-family: Poppins;
  height: 340px;
  width: 400px;
height: 406px;
flex-shrink: 0;
border-radius: 5px; */
background: #FFF;
}
#lost-and-found-petId {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #727271;
  margin-bottom: 10px;
}
#button-lost {
  width: 65px;
  height: 32px;
  top: 641px;
  left: 475px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  background: #e1f4f8;
  color: #1fbfc2;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  padding-top: 5px;
}
#lost-and-found-reward {
  align-items: end;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  /* margin-left: 37px; */
  margin-top: 5px;
  justify-content: end;
  justify-items: end;
  width: 100%;
  text-align: end;
  position: relative;
  align-items: end;
}
#lost-and-found-reward2 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 95px;
  margin-top: 5px;
}
#lost-and-found-line {
  width: 100%;
  height: 2px;
  background-color: #f0f1f6;
  display: flex;
}
#lost-and-found-lost-date {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 9px;
  width: 100%;
}
#button-found {
  width: 65px;
  height: 32px;
  top: 641px;
  left: 475px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  background: #f5790c1a;
  color: #f5790c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  padding-top: 5px;
}
#button-reunited {
  width: 82px;
  top: 641px;
  left: 475px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  background: #e1f4f8;
  color: #1fbfc2;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  padding-top: 5px;
  height: 33px;
}
#lost-and-found-post-date {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  margin-left: 20px;
  margin-top: 9px;
  text-align: end;
  width: 100%;
  justify-content: end;
}

@media (max-width: 768px) {
  .petProfileCard2 {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .petProfileCard2 {
    width: 100%;
  }
}
