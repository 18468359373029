.no-scroll {
    overflow: hidden;
  }

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1000;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #1FBFC20D;
}

.header p {
    font-size: 18px;
    font-weight: 500;
    color: #121212;
}

.popupContainer {
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    overflow: hidden;
    height: auto;
    /* max-height: 90%;
    overflow-y: auto; */
    font-family: Poppins;
    width: 30%;
    max-width: 450px;
  }

.mainContainer {
    background-color: white;
    padding: 25px 35px 50px 35px;
    max-height: 90vh; 
    overflow-y: auto; 
    font-family: Poppins;
}

.mainContainer p {
  color: #121212;
  font-size: 12px;
  font-weight: 500;
}


/* General styling for the range input */
input[type="range"] {
  -webkit-appearance: none; /* Remove default styling */
  width: 100%;
  height: 3px;
  outline: none;
}

/* Styling the track for WebKit browsers (Chrome, Safari) */
input[type="range"]::-webkit-slider-runnable-track {
  height: 3px;
  background: #1FBFC2;
  border-radius: 5px;
}

/* Styling the thumb for WebKit browsers */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background: #1FBFC2;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -3px; /* Center the thumb with the track */
}

/* Styling the track for Firefox */
input[type="range"]::-moz-range-track {
  height: 8px;
  background: #1FBFC2;
  border-radius: 5px;
}

/* Styling the thumb for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #1FBFC2;
  cursor: pointer;
  border-radius: 50%;
}

/* Styling the track for Internet Explorer */
input[type="range"]::-ms-track {
  height: 8px;
  background: #1FBFC2;
  border-radius: 5px;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #1FBFC2;
  cursor: pointer;
  border-radius: 50%;
}





