.imageTextContainerContact {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fb32b222b282f46d5bb4fe371cd267ed3"); /* Replace with the actual image path */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-text {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 700;
  line-height: 67.2px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.Reach-us-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container-horizontal {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #00000012;
  width: 425px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;

  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.card-title {
  margin-bottom: 8px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.20000000298023224px;
  text-underline-position: from-font;
  color: #131313;
  text-decoration-skip-ink: none;
}

.card-description {
  color: #787878;

  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-container-horizontal {
    flex-wrap: wrap;
  }

  .card {
    flex: 1 1 100%;
  }
}

.contact-form-container {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.contact-form span {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.form-group {
  margin-bottom: 0px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #e4e4e4;
  outline: none;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  color: #fff;
  background-color: #f5790c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.GetinTouch {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}

@media (max-width: 768px) {
  .contact-form-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .submit-btn {
    font-size: 14px;
    padding: 12px;
  }
}
.card-button-socialmedia-contact {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
}


/* naveen */

.container-fluid-contact {
  position: relative;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover; 
  z-index: -1; 
}

.form-container-contact {
  /* margin-top: 60px; */
  width: 100%;
  max-width: 500px;
  padding-top: 20px;
  padding-bottom: 20px;

}

.GetinTouch-contact {
  margin-bottom: 15px;
  display: block;
  text-align: center;
font-family: Poppins;
font-size: 40px;
font-weight: 500;
line-height: 56px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #fff

}

.form-group {
  margin-bottom: 1.5rem;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.submit-btn-contact {
  display: block;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #F5790C;
  color: white;
  cursor: pointer;
  width: 150px;
  float: right;
  height: 50px;
font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-underline-position: from-font;
text-decoration-skip-ink: none;


}

.submit-btn-contact:hover {
  background-color: #F5790C;
}

@media (max-width: 768px) {
  .form-container-contact {
    padding: 15px;
  }

  .GetinTouch-contact {
    font-size: 1.5rem;
  }
}
