.testimonials {
    margin-top: 150px;
    overflow: hidden;
    position: relative;
    padding: 0 20px;
  }
  .sectionTitle {
    color: #131313;
    text-align: center;
    font: 500 40px/1.4 Poppins, sans-serif;
    margin-bottom: 79px;
  }
  
  .testimonialsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    position: relative;
    margin-bottom: 150px;
  }
  
  .testimonialCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 32vw;
    transition: transform 0.3s ease;
    z-index: 1;
    position: relative;
  }
  .testimonialCard:nth-child(2) {
    transform: scale(1.1);
    z-index: 2;
    width: 32vw;
  }
  
  .testimonialContent{
    padding-top: 50px;
  }
  
  .testimonialImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    position: absolute;
    right: 40%;
    bottom: 13vw;
    border: 5px solid #ffe1c8;
  }
  

  .testimonialName {
    color: #131313;
    font: 500 20px/1.4 Poppins, sans-serif;
    margin-bottom: 5px;
  }
  
  .testimonialPetName {
    color: rgba(83, 83, 83, 0.5);
    font: 400 13px/1.4 Poppins, sans-serif;
    margin-bottom: 15px;
  }
  
  .testimonialText {
    color: #666;
    font: 400 13px/1.4 Poppins, sans-serif;
    margin-bottom: 20px;
  }
  
  .testimonialRating {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .starIcon {
    width: 20px;
    height: 20px;
  }
  
  @media (max-width: 991px) {

    .sectionTitle {
      font-size: 32px;
    }
    .testimonials {
      margin-top: 40px;
      padding: 0 10px;
    }
    .testimonialImage {
      top: -39px;
      right: 37%;
    }
    .testimonialCard:nth-child(2) {
      width: 100%;
      z-index: 0;
    }
  
    .testimonialsContainer {
      flex-direction: column;
      gap: 54px;
      margin-bottom: 40px;
    }
    .testimonialCard {
        width: 100%;
        transform: none !important;
        z-index: 1;
      }
  }