.navbar {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.logo-img {
  height: 50px;
  padding-top: 8px;
}

.nav-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.nav-elements.active {
  display: block;
}

.nav-elements ul {
  list-style: none;
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 0;
}

.nav-elements ul li {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-elements ul li a {
  text-decoration: none;
  color: black;
  font-size: 20px;
}

.nav-elements ul li:hover,
.nav-elements ul li a:hover {
  color: blue; /* Change to black on hover */
}
